import { DashboardInputDto } from "../../dtos/dashboard/DashboardInputDto";
import { InputForCreateOrUpdateEmployeeBillingDto } from "../../dtos/employeeBilling/InputForCreateOrUpdateEmployeeBillingDto";
import instance, { del, get, patch, post } from "../../utills/BaseService";

export async function getAllEmployeeBillingData(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/employeeBilling/getAllEmployeeBillingData', input);
};

export async function createEmployeeBilling(dispatch: any, input: InputForCreateOrUpdateEmployeeBillingDto): Promise<any> {
   return await post(dispatch, '/employeeBilling/createEmployeeBilling', input);
};

export async function getTopBilledEmployee(dispatch: any, input: DashboardInputDto): Promise<any> {
   return await get(dispatch, '/employeeBilling/getTopBilledEmployee', input);
};

export async function getTopBilledProject(dispatch: any, input: DashboardInputDto): Promise<any> {
   return await get(dispatch, '/employeeBilling/getTopBilledProject', input);
};

export async function getTopTrackingProjects(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/employeeBilling/getTopTrackingProjects', input);
};
export async function getTopBillingProjects(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/employeeBilling/getTopBillingProjects', input);
};

export async function getTotalTrackAndBillTimeByProj(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/employeeBilling/getTotalTrackAndBillTimeByProj', input);
};