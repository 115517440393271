import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ProtectAdminRoutes = (props: any) => {
    const navigate = useNavigate();
    const role = localStorage.getItem('role') as string;
    const [isAdmin, setIsAdmin] = useState(false);

    const chekUserRole = () => {
        if (role.toUpperCase() === 'ADMIN') {
            setIsAdmin(true);
        }
        else {
            setIsAdmin(false);
            navigate('/dashboard');
        }
    }

    useEffect(() => {
        chekUserRole();
    }, [])

    return (
        <React.Fragment>
            {isAdmin ? props.children : null}
        </React.Fragment>
    )
}

export default ProtectAdminRoutes;