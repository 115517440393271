
//get next first and last day of week from date array function
export function GetNextFirstAndLastDayOfWeek(curr: any) {
    const firstDay = new Date(curr[0].getFullYear(), curr[0].getMonth(), curr[0].getDate() + 7); // Start from the next day after Sunday
    const lastDay = new Date(curr[1].getFullYear(), curr[1].getMonth(), curr[1].getDate() + 7 - curr[1].getDay());

    return {
        first: firstDay,
        last: lastDay
    };
}