import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { InputForCreateOrUpdateEmployeeBillingDto } from '../../dtos/employeeBilling/InputForCreateOrUpdateEmployeeBillingDto';
import { useEffect } from 'react';
import { ProjTrackAndBillSerDto } from '../../dtos/employeeBilling/ProjTrackAndBillSerDto';
import { useSelector } from 'react-redux';
import { AppMode } from '../../data/AppMode';
import { AppModeState } from '../../dtos/common/AppModeState';
import colors from '../../shared/json/Colors.json';

export const ProjTrackAndBillTimeForReport = (props: any) => {

  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find(md => md.name === modeName); 

  const Data: InputForCreateOrUpdateEmployeeBillingDto[] = props.data;
  const catg: string[] = [];
  const serData: ProjTrackAndBillSerDto[] = [];
  const [showChart, setShowChart] = React.useState(false);
  const [chart, setChart] = React.useState<any>(null);

  Data?.forEach(val => {
    if (!catg.includes(val.ProjectName.toUpperCase())) {
      catg.push(val.ProjectName.toUpperCase())
    }
  })


  Data?.forEach(val => {
    serData.push({
      name: val.EmployeeName,
      group: 'track',
      data: []
    } as ProjTrackAndBillSerDto)
    serData.push({
      name: val.EmployeeName,
      group: 'bill',
      data: []
    } as ProjTrackAndBillSerDto)
  })

  const handleData = () => {
    serData?.forEach(emp => {
      catg.forEach(proj => {
        const findData = Data.find(val => val.EmployeeName.toLowerCase() === emp.name.toLowerCase() && val.ProjectName.toLowerCase() === proj.toLowerCase())
        if (emp.group === 'track') {
          const num = typeof findData?.TrackTimeInMinute === 'undefined' ? '0' : Number(findData?.TrackTimeInMinute as number / 60).toFixed(2)
          emp.data.push(Number(num) ?? 0)
        }
        else if (emp.group === 'bill') {
          const num = typeof findData?.BillingTimeInMinute === 'undefined' ? '0' : Number(findData?.BillingTimeInMinute as number / 60).toFixed(2)
          emp.data.push(Number(num) ?? 0)
        }
      })
    });

    serData.forEach(emp => {
      if (emp.group === 'track') {
        emp.name = emp.name + ' Track'
      }
      else {
        emp.name = emp.name + ' Bill'
      }
    })

    const legendColor: string[] = [];
    serData.forEach(val=>legendColor.push(mode?.color as string));
    

    const updatedChart: any = {
      series: serData,
      options: {
        chart: {
          type: 'bar',
          height: heightOfBar,
          stacked: true,
          animations: {
            enabled: false
          }
        },
        markers: {
          size: 0
        },
        tooltip: {
          theme: 'dark',
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },

        dataLabels: {
          formatter: (val: any) => {
            return val
          },
          maxItems: 5
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '30%',
            barHeight: '30%',
            rangeBarOverlap: false,
          }
        },

        xaxis: {
          categories: catg,
          labels: {
            formatter: (val: any) => {
              return Number(val).toFixed(2) + 'H'
            },
            style: {
              colors: legendColor
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: legendColor,
              width: 500
            }
          }
        },
        fill: {
          opacity: 1,
        },
        colors: colors.colors,
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          labels: {
            colors: legendColor
          }
        },
        responsive: [
          {
            breakpoint: 991,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom",

              }
            }
          }
        ],
      },
      tooltip: {
        fillSeriesColor: true
      }

    };


    setChart(updatedChart)
    setShowChart(true);
  }



  useEffect(() => {
    handleData();
  }, [Data])

  const heightOfBar = 100 + catg?.length * 100;

  return (
    <div>
      {showChart && (<ReactApexChart options={chart?.options} series={chart?.series} type="bar" height={heightOfBar} />)}
    </div>
  )
}