import { createSlice } from "@reduxjs/toolkit";

interface State {
    profilePic: string
}
interface Action {
    type: string,
    payload: string
}

const profilePicSlice = createSlice({
    name: "profilePicSlice",
    initialState: {
        profilePic: 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'
    },
    reducers: {
        setProfilePic(state: State, action: Action) {
            state.profilePic = action.payload
        }
    }
});

export const { setProfilePic } = profilePicSlice.actions;
export default profilePicSlice.reducer;