import * as react from 'react';
import { Card } from 'primereact/card';
import { useCallback, useRef, useState } from 'react';
import Cron, { CronError } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import '../../css/components/cronSettings.css';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { AppMode } from '../../data/AppMode';
import { Themes } from '../../data/Themes';
import { AppModeState } from '../../dtos/common/AppModeState';
import { ThemeState } from '../../dtos/common/ThemeState';
import { cronSetup } from '../../service/emailSettings/EmailSettingsServices';
import { InputText } from 'primereact/inputtext';
import { findSettingsByKey, updateOrCreateByKey } from '../../service/common/commonService';
import { Checkbox } from 'primereact/checkbox';
import { Cron_Time, Is_Stop_Mails } from '../../shared/constant/StarValue';

export const CronSettings = (props: any) => {
    const inputRef = useRef<any>(null);
    const [value, setValue] = useState(props?.data);
    const toast = useRef<any>(null);
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const dispatch = useDispatch();
    const [stopEmail, setStopEmail] = useState(true);

    const customSetValue = useCallback(
        (newValue: string) => {
            setValue(newValue);
            inputRef.current?.setValue(newValue);
        },
        [inputRef]
    );
    const [error, onError] = useState<CronError>();

    const accept = async () => {
        const inputForCornSetup = {
            Key: Cron_Time,
            Value: value
        }
        await cronSetup(dispatch, inputForCornSetup)
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'You have successfully change the cron settings', life: 3000 });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected the process', life: 3000 });
    }

    const rejectStopMail = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected the process', life: 3000 });
    }

    const changeStopMail = (isStop: boolean) => {
        setStopEmail(isStop)
        confirmDialog({
            message: !isStop ? `Are you sure you want to start the mail with cron value ${value} ?` : 'Are you sure you want to stop the mail?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            headerStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            style: { width: '350px', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            contentStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            acceptClassName: 'p-button-danger',
            accept: () => handleStopMails(isStop),
            reject: rejectStopMail
        });
    };

    const changeCorn = () => {
        confirmDialog({
            message: 'Are you sure you want change the cron job setup time?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            headerStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            style: { width: '350px', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            contentStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };
    const getCornTime = async () => {
        const time = await findSettingsByKey(dispatch, { key: 'CronTime' });
        if (time !== null) {
            setValue(time.Value)
        }
    }

    const getStopEmailVal = async () => {
        const isStop = await findSettingsByKey(dispatch, { key: Is_Stop_Mails });

        if (isStop !== null && isStop?.Value === 'true') {
            setStopEmail(true)
        }
        else if (isStop !== null && isStop?.Value === 'false') {
            setStopEmail(false)
        }
    }

    const handleStopMails = async (isStop: boolean) => {

        const inputForCornSetup = {
            Key: Is_Stop_Mails,
            Value: `${isStop}`
        }
        await updateOrCreateByKey(dispatch, inputForCornSetup);

        if (isStop) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'You have successfully stop the cron settings', life: 3000 });
        }
        else {
            const inputForCornSetup = {
                Key: 'CronTime',
                Value: value
            }
            await cronSetup(dispatch, inputForCornSetup);
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'You have successfully start the cron settings', life: 3000 });
            return;
        }

    }

    react.useEffect(() => {
        getCornTime();
        getStopEmailVal()
    }, [])

    react.useEffect(() => {
        if (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'error', life: 3000 });
        }
    }, [error])

    return (
        <Card style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="cron-container">
                <h1>Corn Settings Setup</h1>
                <InputText
                    value={value}
                    onBlur={(event: any) => {
                        setValue(event.target.value);
                    }}
                    className='cron-input'
                />

                <Cron value={value} setValue={customSetValue}  onError={onError} />
                <Button label='Change Corn Settings' onClick={changeCorn} style={{ backgroundColor: ThemeColors?.primaryColor }} />
                <div className="flex align-items-center">
                    <Checkbox id='stopmail' onChange={(e: any) => changeStopMail(e.checked)} checked={stopEmail}></Checkbox>
                    <label htmlFor="stopmail" className="ml-2">Stop Mails</label>
                </div>
            </div>
        </Card>
    )
}