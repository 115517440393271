import { useEffect, useRef, useState } from "react"
import { AllAuditLog } from "../../dtos/auditLog/AllAuditLog";
import { getAllLog } from "../../service/auditLog/AuditLogService";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { LazyState } from "../KPI/KPITable";
import { AppMode } from "../../data/AppMode";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { DateRange } from "react-date-range";

export const AuditLog = () => {
    const [allLog, setAllLog] = useState<AllAuditLog[]>([]);
    const dispatch = useDispatch();
    const [totalCount, setTotalCount] = useState<number>(0);
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const localization = useSelector((state: any) => state.localization.localization);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    let yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1)
    const [date, setDate] = useState<Date[]>([yesterday, new Date()]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datePickerRef = useRef<any>(null);
    const [lazyState, setlazyState] = useState<any>({
        page: 0,
        first: 0,
        rows: 10,
        sortField: 'id',
        sortOrder: 1 as number,
        filters: ''
    });

    function convertToUTC(date: Date) {
        var currentDateUtc = date;
        // Set the time to midnight (12:00 AM)
        currentDateUtc.setUTCHours(0);
        currentDateUtc.setUTCMinutes(0);
        currentDateUtc.setUTCSeconds(0);
        currentDateUtc.setUTCMilliseconds(0);

        return currentDateUtc.toISOString();

    }

    const getAllAuditLog = async () => {
        const param: any = {
            Limit: lazyState.rows,
            Page: lazyState.page,
            SortBy: lazyState.sortOrder === 1 ? 'ASC' : 'DESC',
            SortProperty: lazyState.sortField,
            StartDate: convertToUTC(date[0]),
            EndDate: convertToUTC(date[1])
        }
        if (lazyState.filters && lazyState.filters.length > 0) {
            param.Filter = lazyState.filters
        }
        if (!showDatePicker) {
            const data = await getAllLog(dispatch, param);


            setAllLog(data?.List);
            setTotalCount(data?.TotalCount)
        }
    }

    const onPageChange = (event: any) => {
        setlazyState(event)
    };

    const onSort = (event: any) => {
        setlazyState((prev: LazyState) => {
            const newState: LazyState = {
                ...prev, // Copy all properties from the previous state
                sortOrder: event.sortOrder, // Update sortOrder
                sortField: event.sortField, // Update sortField
            };
            return newState;
        });
    };

    //This function will execute when user select dates from date range calender which will show after clicking the date range button
    const handleDateRange = (item: any) => {
        const dateRan = [];
        dateRan.push(item?.selection?.startDate, item?.selection?.endDate);
        setDate(dateRan);
    };

    const handleDatePicker = () => {
        setShowDatePicker(true);
    }

    //#region all UseEffects
    const handleClickOutside = (event: any) => {
        if (
            datePickerRef.current &&
            !datePickerRef.current.contains(event.target)
        ) {
            setShowDatePicker(false);
        }
    };

    const startToolbar = () => {
        return (
            <div>

                {showDatePicker && (
                    <div
                    
                        ref={datePickerRef}
                        className="date-range"
                        style={{
                            backgroundColor: ThemeColors?.primaryColor,
                            color: "white",
                        }}
                    >
                        <DateRange
                            onChange={(item) => handleDateRange(item)}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={[
                                {
                                    startDate: date[0],
                                    endDate: date[1],
                                    key: "selection",
                                },
                            ]}
                            direction="horizontal"
                        />

                    </div>
                )}
                <div className="custom_date_range"  onClick={handleDatePicker}>
                    {String(date[0].getDate()).padStart(2, "0")}/
                    {String(date[0].getMonth() + 1).padStart(2, "0")}/
                    {date[0].getFullYear()} -{" "}
                    {String(date[1].getDate()).padStart(2, "0")}/
                    {String(date[1]?.getMonth() + 1).padStart(2, "0")}/
                    {date[1].getFullYear()}
                </div>
            </div>
        )
    };

    const endToolbar = () => {
        return (
            <div className="endToolbar">
                <InputText type="search" placeholder={` ${localization?.Search || 'Search'}...`} onChange={(e) => {
                    setTimeout(() => {
                        setlazyState((prev: LazyState) => {
                            const newState = {
                                ...prev,
                                filters: e.target.value as string
                            }
                            return newState;
                        })
                    }, 300)
                }} />
            </div>
        )
    };

    useEffect(() => {
        getAllAuditLog();
    }, [lazyState, showDatePicker]);


    useEffect(() => {
        // Attach event listener when component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up the event listener when component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
            <Toolbar className="mb-3" start={startToolbar} end={endToolbar} style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} />
            <Card>
                <DataTable
                    style={{  }}
                    className="performance-table audit-log-table"
                    value={allLog}
                    dataKey="id"
                    lazy
                    paginator
                    totalRecords={totalCount}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    onSort={onSort}
                    onPage={onPageChange}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    showGridlines
                    rowsPerPageOptions={[8, 10, 20]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Total Records {totalRecords} ">
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="BrowserInfo" header='Browser Info' ></Column>
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="ClientIpAddress" header='Client Ip' ></Column>
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="Exception" header='Exception' ></Column>
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="ExecutionTime" header='ExecutionTime' ></Column>
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="MethodName" header='MethodName' ></Column>
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="Parameters" header='Parameters' ></Column>
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="EmployeeCode" header='Employee Code' ></Column>
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="ReturnValue" header='Return Value' ></Column>
                    <Column sortable bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="ExceptionMessage" header='Exception Message' ></Column>
                </DataTable>
            </Card>
        </div>
    )
}