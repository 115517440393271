// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unhandled-err {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2.9rem 0;
}

.unhandled-err>img {
    width: 20%;
}

.unhandled-err>p {
    width: 30%;
    text-align: center;
}

.unhandled-err-btn {
    display: flex;
    gap: 20px;
}

.page-not-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.9rem 0;
}

.page-not-found>img{
    width: 20%;
}`, "",{"version":3,"sources":["webpack://./src/css/components/unhandledError.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".unhandled-err {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 2rem;\n    padding: 2.9rem 0;\n}\n\n.unhandled-err>img {\n    width: 20%;\n}\n\n.unhandled-err>p {\n    width: 30%;\n    text-align: center;\n}\n\n.unhandled-err-btn {\n    display: flex;\n    gap: 20px;\n}\n\n.page-not-found{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 2.9rem 0;\n}\n\n.page-not-found>img{\n    width: 20%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
