import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { loginFailed, loginStart, loginSuccess } from '../../store/slices/userSlice';
import { loginApi } from '../../service/login/loginService';
import pic from "../../images/login.png";
import { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { setLogin } from '../../store/slices/loginSlice';
import logo from '../../images/epms_logo_main.png';
import { getCurrentUserLoginInfo } from '../../service/user/userService';
import { Button } from 'primereact/button';
import '../../css/components/login.css';
import { Themes } from '../../data/Themes';
import { ThemeState } from '../../dtos/common/ThemeState';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';

export const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef<any>(null);
  const [emailLengthError, setEmailLengthError] = useState('');
  const [pwdLengthError, setPwdLengthError] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find(th => th.name === themeName);
  const [eyeIcon, setEyeIcon] = useState<string>("pi pi-eye-slash");
  const [passwordType, setPasswordType] = useState('password');


  const handleSettingsVal = (input: any[]) => {
    const keys = ['Language', 'Mode', 'Theme'];
    keys.forEach((val) => {
      const findVal = input.find(k => val.includes(k.Key));
      localStorage.setItem(findVal.Key, findVal.Value)
    })
  }

  const handleClick = async () => {
    setDisableBtn(true)
    let err: string = ''
    if (email.length === 0 && password.length === 0) {
      setPwdLengthError('Password field is required');
      setEmailLengthError('Email field is required');
      err = 'error'
    }

    if (email.length === 0) {
      setEmailLengthError('Email field is required');
      err = 'error'
      // return;
    }
    if (password.length === 0) {
      setPwdLengthError('Password field is required');
      err = 'error'
      // return;
    }
    if (email.length > 0 && email.replace(/\s/g, '').length == 0) {
      setEmailLengthError('Please enter a valid input');
      err = 'error'
    }
    if (password.length > 0 && password.replace(/\s/g, '').length == 0) {
      setPwdLengthError('Please enter a valid input');
      err = 'error'
    }

    if (err.length === 0) {
      setEmailLengthError('');
      setPwdLengthError('')

      const credentials = {
        Email: email,
        Password: password,
        tenancyName: 'default'
      }
      try {
        dispatch(loginStart(credentials))
        //login api
        const res: any = await loginApi(credentials);
        if (res.data.Result !== null) {

          const userDetails = {
            Email: res.data.Result.UserDetails.Email
          }
          dispatch(loginSuccess(userDetails));

          //set the role and exp time in local storage
          localStorage.setItem('TokenExpTime', res.data.Result.AuthenticateResult.ExpTimeInHour)

          //set token in local storage
          localStorage.setItem("token", res?.data?.Result?.AuthenticateResult?.Token);


          //set settings to local storage
          if (res.data.Result.Settings.length > 0) {
            handleSettingsVal(res.data.Result.Settings)
          }

          //get logged in user role
          const getRoleOfLoggedInUSer = await getCurrentUserLoginInfo(dispatch);
          localStorage.setItem('role', getRoleOfLoggedInUSer?.role)

          navigate("/dashboard");
          window.location.reload();

        }
        if (res.data.Result === null) {
          toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.Error, life: 3000 });
        }
      } catch (error: any) {
        dispatch(loginFailed(error));
        toast.current.show({ severity: 'error', summary: 'Error', detail: error?.response?.data.Error, life: 3000 });
      }
    }
    setDisableBtn(false);
  }

  const handleToggle = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      setEyeIcon("pi pi-eye")
    }
    else {
      setPasswordType("password");
      setEyeIcon("pi pi-eye-slash")
    }
  }

  const handleEnterKey = (event: any) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  return (
    <div className='login'>
      <span className="login-loading-bar">
        {disableBtn && <ProgressSpinner />}
      </span>
      <Toast ref={toast} />
      <div className='left-login'>
        <div className='text-on-image-1'><img src={logo} alt='logo' className='login-logo' /></div>
        <div className='text-on-image-2'><span>Efficiently</span> Monitor Employee <span>Performance</span></div>
        <img src={pic} className='login-image' />
      </div>
      <div className='right-login'>
        <div className='login-inputs'>
          <h2 className='sign-in-text'>Sign In With</h2>
          <div className='login-inputs-form' >
            <div>
              <input type='text' onKeyDown={handleEnterKey} className='login-input' placeholder='Email Address or User Name' onChange={(e) => {
                setEmail(e.target.value);
                setEmailLengthError(''); // Reset email length error when the user starts typing
              }} required />
              {emailLengthError && <span className='err-msg'>{emailLengthError}</span>}
            </div>
            <div className='password-box'>
              <input type={passwordType} onKeyDown={handleEnterKey} className='login-input' placeholder='Enter Password' onChange={(e) => {
                setPassword(e.target.value)
                setPwdLengthError('')
              }} required />
              <span><i className={eyeIcon} style={{ fontSize: '1.2rem' }} onClick={handleToggle} ></i></span>
              {pwdLengthError && <span className='err-msg'>{pwdLengthError}</span>}
            </div>
            <Button className='login-btn' label='Sign In' onClick={handleClick} disabled={disableBtn} style={{ backgroundColor: ThemeColors?.primaryColor, border: 'none' }} />
          </div>
        </div>
      </div>
    </div>
  )

}
