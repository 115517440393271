import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { GetAllProjectItemsDto } from "../../dtos/project/GetAllProjectItemsDto";
import { GetAllProjectDto } from "../../dtos/project/GetAllProjectDto";
import { getProjectByUser } from "../../service/project/ProjectService";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import '../../css/components/projectSettings.css';
import { AppMode } from "../../data/AppMode";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Checkbox } from "primereact/checkbox";
import { deleteAndCreateProjectSettings, getAllProjectFromSettings } from "../../service/projectSetting/ProjectSettingService";
import { CreateProjectSettingDto } from "../../dtos/projectSetting/CreateProjectSettingDto";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";


export const ProjectSettings = () => {
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const localization = useSelector((state: any) => state.localization.localization);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const [searchText, setSearchText] = useState<string>('');
    const [allProjects, setAllProjects] = useState<GetAllProjectItemsDto[]>([]);
    const [checkedProjects, setCheckedProjects] = useState<any>({});
    const dispatch = useDispatch();
    const [visibleProjects, setVisibleProjects] = useState<CreateProjectSettingDto[]>([]);
    const toast = useRef<any>(null);

    const getAllProjects = async () => {
        const projects: GetAllProjectDto = await getProjectByUser(dispatch);
        const filteredProjects: GetAllProjectItemsDto[] = projects?.items?.filter(proj => {
            return proj.name.toLowerCase().includes(searchText.toLowerCase())
        })
        setAllProjects(filteredProjects)
    }

    const handleProjectCheck = (e: any, proj: CreateProjectSettingDto) => {
        if (!e.checked) {
            setVisibleProjects(prev => [...prev, proj]);
        } else {
            setVisibleProjects(prev =>
                prev.filter((p: CreateProjectSettingDto) => p.projectId !== proj.projectId && p.projectName !== proj.projectName));
        }
        setCheckedProjects((prevState: any) => ({
            ...prevState,
            [proj.projectId]: !prevState[proj.projectId] // Toggle the checked status
        }));

    }


    const accept = async () => {
        await deleteAndCreateProjectSettings(dispatch, visibleProjects);
        await getAllVisibleProjects();
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'You have successfully save the project settings!', life: 3000 });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const getAllVisibleProjects = async () => {
        const projects: CreateProjectSettingDto[] = await getAllProjectFromSettings(dispatch);
        setVisibleProjects(projects);

        const initialCheckedItems = projects.reduce((acc: any, proj: any) => {
            acc[proj.projectId] = true;
            return acc;
        }, {});

        setCheckedProjects(initialCheckedItems)
    }

    const handleSaveBtnClick = () => {
        confirmDialog({
            message: 'Are you sure you want to save the projects?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            headerStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            style: { width: '350px', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            contentStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            accept,
            reject
        });

    };


    useEffect(() => {
        getAllProjects()
    }, [searchText]);

    useEffect(() => {
        getAllVisibleProjects()
    }, [])

    return (
        <div className="row">
            <div className="col-md-12">
                <Toast ref={toast} />
                <ConfirmDialog />
                <Card style={{
                    paddingBottom: '1rem',
                    backgroundColor: mode?.backgroundSecondaryColor,
                    color: mode?.color,
                }} 
                className="p-0"
                >
                    <div className="proj-settings-head">
                        <h4>{localization?.ProjectsThatShowsTrackingTimeAsBillingTime || "Projects That Shows Tracking Time As Billing Time"}</h4>
                        <InputText placeholder="Search..." value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                    </div>

                    {/* show the projects */}
                    <div className="project_single">
                    <div className="row">
                        {allProjects?.map(proj => (
                            <div className="col-md-6 col-lg-4 mb-3 project_single_box">
                                <div className="card h-100 d-flex flex-row align-items-center justify-content-between gap-2 p-2">
                                    <span>{proj?.name}</span>
                                    <Checkbox
                                        checked={!checkedProjects[proj.id]}
                                        onChange={(e) => handleProjectCheck(e, { projectId: proj.id, projectName: proj.name, baseUrl: proj.baseUrl })}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    </div>
                    

                <div className="p-card-footer proj-settings-btn pt-3 d-flex justify-content-end">
                    <Button label="Save" onClick={handleSaveBtnClick} style={{ backgroundColor: ThemeColors?.primaryColor, border: 'none' }} />
                </div>
                </Card>
            </div>
        </div>
    )
}