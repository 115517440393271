export function isDateValid(date: Date) {
    if (Object.prototype.toString.call(date) === "[object Date]") {
        if (!isNaN(date.getTime())) {
            return true
            // date is valid
        } else {
            return false
        }
    } else {
        return false
        // date is not valid
    }
}