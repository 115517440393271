import * as react from 'react';
import { PerformanceViewInputDto } from '../../dtos/performance/PerformanceViewInputDto';
import { Card } from 'primereact/card';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { MainPerformanceDto } from '../../dtos/performance/MainPerformanceDataDto';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CreatePerformanceDto } from '../../dtos/performance/CreatePerformanceDto';
import { createPerformance, getPerformanceWithKPIByEmployee, performanceSubmittedOrNot } from '../../service/performance/PerformanceService';
import { useDispatch, useSelector } from 'react-redux';
import { PerformanceInputDto } from '../../dtos/performance/PerformanceInputDto';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { GetOneUserDto } from '../../dtos/user/GetOneUserDto';
import { Calendar } from 'primereact/calendar';
import '../../css/components/performanceView.css';
import { ThemeState } from '../../dtos/common/ThemeState';
import { Themes } from '../../data/Themes';
import { AppModeState } from '../../dtos/common/AppModeState';
import { AppMode } from '../../data/AppMode';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { LoggedInUserData } from '../../shared/functions/LoggedInUserData';
import { InputNumber } from 'primereact/inputnumber';
import { setPath } from '../../store/slices/routePathSlice';
import { PerformanceOptionsDto } from '../../dtos/performance/PerformanceOptionsDto';
import { isNotUpcomingDate } from '../../shared/functions/IsNotUpcomingDateFunc';
import DatePicker from "react-datepicker";
import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';
import { ISTToDate } from '../../shared/functions/ISTToDate';
import { Dialog } from 'primereact/dialog';
import { getTheMonthName } from '../../shared/functions/GetMonthName';

export const PerformnaceView = () => {
    const props = useSelector((state: any) => state.performanceViewInput);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState<MainPerformanceDto>(props.data);
    const [date, setDate] = useState<any>(props?.date);
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const [reviewMode, setReviewMode] = useState(props.mode)
    const toast = useRef<any>(null);
    const [disableButtons, setDisableButtons] = useState(props.data.IsSubmitted);
    const validRating = new RegExp('^(0?[1-9]|[1-9][0-9])$');
    const [score, setScore] = useState<number>(props.data.AverageRating === null ? 0 : props.data.AverageRating);
    const isAdmin = localStorage.getItem('role');
    const [isOpenAdminReason, setIsOpenAdminReason] = useState<boolean>(false);
    const loggedInUserRole: string | null = localStorage.getItem('role');
    const [isValidRating, setIsValidRating] = useState<{ [kpiId: string]: boolean }>({});
    const [isVisibleChangeReson, setIsVisibleChangeReson] = useState<boolean>(false);
    const [changeReasonVal, setChangeReasonVal] = useState<string>('');
    // const [isLoading, setIsLoading] = useState(false);

    const handleRoute = () => {
        localStorage.setItem('lastRoute', '/performance')
        navigate('/performance');
        dispatch(setPath({ path: '/performance' }))
    }


    const handlePrevMonth = () => {
        if (date) {
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            firstDay.setMonth(firstDay.getMonth() - 1);

            setDate(firstDay);
        }
    };

    const handleNextMonth = () => {
        if (date) {
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            firstDay.setMonth(firstDay?.getMonth() + 1);

            //if the date is upcoming date then set it current date
            if (!isNotUpcomingDate(firstDay.toString())) {
                setDate((prev: any) => {
                    const newDate = prev;
                    return newDate
                })
                toast.current.show({ severity: 'warn', summary: 'Sorry!', detail: "You can't go to the current and upcoming months!", life: 3000 });
                return;
            }

            setDate(firstDay);
        }
    };


    const handleRatings = (e: any) => {
        const { employeeCode, value, kpiID } = e;

        // Check if the number is within the valid range
        const isValid = value >= 1 && value <= 99;

        // Update the validation status for the specific KPI
        setIsValidRating((prevState: any) => ({
            ...prevState,
            [kpiID]: !isValid
        }));

        //set the ratings value to data.....
        if (data) {
            setData((prev: any) => {
                if (prev.EmployeeCode === employeeCode) {
                    const updatedKPI = data?.KPIs?.map(kpi => {
                        return kpi.kpiId === kpiID ? {
                            ...kpi, rating: value,

                        } : kpi;
                    });
                    const result = {
                        EmployeeCode: prev?.EmployeeCode,
                        EmployeeName: prev?.EmployeeName,
                        KPIs: updatedKPI,
                        AverageRating: prev.AverageRating
                    }
                    return result
                } else {
                    return data
                }
            })
        }
    }

    const handleReviews = (e: any) => {
        const { employeeCode, value, kpiID } = e;

        //set the ratings value to data.....
        if (data) {

            setData((prev: MainPerformanceDto) => {
                if (prev.EmployeeCode === employeeCode) {
                    const updatedKPI = data?.KPIs?.map(kpi => {
                        return kpi.kpiId === kpiID ? {
                            ...kpi, review: value,

                        } : kpi;
                    });
                    const result = {
                        EmployeeCode: prev?.EmployeeCode,
                        EmployeeName: prev?.EmployeeName,
                        KPIs: updatedKPI,
                        AverageRating: prev.AverageRating
                    }
                    return result
                } else {
                    return data
                }
            })
        }
    }


    const inputValidation = (input: PerformanceInputDto[], isSubmitted: boolean) => {
        let validationRes: boolean[] = [];

        if (!isSubmitted) {
            input.forEach(val => {
                if (val.Rating !== null) {

                    if (!validRating.test(val.Rating?.toString())) {
                        validationRes.push(false)
                    } else {
                        validationRes.push(true)
                    }
                }
                else {
                    validationRes.push(true)
                }
            })
        }
        else {
            input.forEach(val => {
                if (!validRating.test(val.Rating?.toString())) {
                    validationRes.push(false)
                } else {
                    validationRes.push(true)
                }
            })
        }
        return validationRes;
    }

    const handleScoreCalculation = (KPIs: PerformanceOptionsDto[]) => {
        let score: number;

        const totalRating = KPIs.reduce((acc, kpi) => {
            return acc + (kpi.rating * kpi.weightage)
        }, 0);
        const totalWeightage = KPIs.reduce((acc, kpi) => {
            return acc + kpi.weightage
        }, 0);

        score = totalRating / totalWeightage

        return score
    }

    //handle date input
    const handleDate = (e: any) => {
        //if the date is upcoming date then set it current date
        if (!isNotUpcomingDate(e)) {
            setDate((prev: any) => {
                const newDate = prev;
                return newDate
            })
            toast.current.show({ severity: 'warn', summary: 'Sorry!', detail: "You can't go to the current and upcoming months!", life: 3000 });
            return;
        }
        setDate(e);
    }

    const accept = async (isSubmitted: boolean) => {

        //if change reason then open the dialog 
        if (isOpenAdminReason && !isVisibleChangeReson) {
            setIsVisibleChangeReson(true)
            return;
        }

        const input: PerformanceInputDto[] = [];
        const userDetails: GetOneUserDto = await LoggedInUserData(dispatch);

        data?.KPIs.forEach(kp => {
            const inputVal: PerformanceInputDto = {
                KPIID: kp.kpiId,
                Rating: kp.rating,
                Review: kp.review,
                ReviewerID: userDetails?.EmployeeCode,
                KPIName: kp.kpiName.toLowerCase(),
                Weightage: kp.weightage,
                Target: kp.target
            };
            input.push(inputVal);
        });

        const loggedInUser = await LoggedInUserData(dispatch)


        if (data) {

            const performanceInput: CreatePerformanceDto = {
                EmployeeCode: data?.EmployeeCode,
                SubmittedDate: ISTToDate(date) as string,
                AdminReason: changeReasonVal,
                ReviewerID: loggedInUser?.EmployeeCode,
                IsSubmitted: isSubmitted,
                Input: input,
            };
            const checkValidation = inputValidation(performanceInput.Input, isSubmitted);
            if (!checkValidation.includes(false)) {

                //if admin wants to change the score and set reason
                await createPerformance(dispatch, performanceInput);
                setScore(handleScoreCalculation(data?.KPIs))
                if (isSubmitted) {
                    setDisableButtons(true);
                    if (loggedInUserRole !== null && loggedInUserRole === 'admin') {
                        setIsOpenAdminReason(false)
                    }
                    toast.current.show({ severity: 'success', summary: 'Submitted', detail: 'You have successfully submitted the form', life: 4000 });
                }
                else {
                    toast.current.show({ severity: 'success', summary: 'Submitted', detail: 'You have successfully save the draft form', life: 4000 });
                }
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please provide valid rating value.' });
            }
        }

    }

    const handleData = async () => {
        const loggedInUser = await LoggedInUserData(dispatch)
        if (date) {

            const input = {
                submittedDate: ISTToDate(date),
                empCode: params.empId,
                loggedInEmpCode: loggedInUser.EmployeeCode,
                roleName: props.roleName
            }
            const res = await getPerformanceWithKPIByEmployee(dispatch, input);

            if (res) {
                setData(res);
                setChangeReasonVal(res.AdminReason)
                setDisableButtons(res.IsSubmitted);
                setScore(res.AverageRating === null ? 0 : res.AverageRating)
                if (res.IsSubmitted) {
                    setReviewMode('view')
                }
                else {
                    setReviewMode('review')
                }
            }
        }
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected to save the form', life: 3000 });
    }

    const handleSaveDraft = async () => {
        confirmDialog({
            message: 'Are you sure you want to save the draft form?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            headerStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            style: { width: '350px', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            contentStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            accept: () => accept(false),
            reject
        });
    }

    const handleSubmit = () => {
        confirmDialog({
            message: <div style={{ textAlign: 'center' }}>
                <p> Are you sure you want to save and submit this employee form?</p>
                <p> Once you submit the form again, you won't be able to make any further changes.</p>
            </div>,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            headerStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            style: { width: '350px', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            contentStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            accept: () => accept(true),
            reject
        });
    };

    const handleAdminReasonBtn = async () => {

        // if no reason is written
        if (changeReasonVal === null) {
            toast.current.show({ severity: 'warn', summary: 'warning!', detail: 'Please provide reason for change the score!.' });
            return;
        }

        if (changeReasonVal.length === 0) {
            toast.current.show({ severity: 'warn', summary: 'warning!', detail: 'Please provide reason for change the score!.' });
            return;
        }

        await accept(true);
        setIsVisibleChangeReson(false);
        setIsOpenAdminReason(false)
    }

    const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
        <div className="example-custom-input" onClick={onClick} ref={ref}>
            {value}
        </div>
    ));

    const changeResonFooter = () => {
        return (
            <div>
                <Button label="Submit" className="kpi-save-button" onClick={handleAdminReasonBtn} style={{ backgroundColor: ThemeColors?.primaryColor }} />
            </div>
        )
    }

    useEffect(() => {
        handleData();
        setIsValidRating({});
        setIsOpenAdminReason(false)
    }, [date, disableButtons])

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog style={{ backgroundColor: mode?.backgroundSecondaryColor, color: ThemeColors?.primaryColor }} />
            <Card style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
                <div className='main-performance-view'>
                    <div className='performance-view-header' style={{ backgroundColor: ThemeColors?.primaryColor }}>
                        <h4>{getTheMonthName(date)} {reviewMode.toLowerCase()} form for {data?.EmployeeName} ({data?.EmployeeCode})</h4>
                        <div className="date-input">
                            <Button icon={<AiOutlineLeft />} onClick={handlePrevMonth} className="date-comp-prev-btn arr-btn-view" style={{ backgroundColor: '#E4F1FF', color: 'black' }} />
                            <DatePicker selected={date} value={date} customInput={<CustomInput />} onChange={handleDate} dateFormat="MMMM yyyy"
                                showMonthYearPicker />
                            <Button icon={<AiOutlineRight />} onClick={handleNextMonth} className="date-comp-next-btn arr-btn-view" style={{ backgroundColor: '#E4F1FF', color: 'black' }} />
                        </div>
                    </div>
                    {isAdmin?.toLowerCase() === 'admin' && <div className='average-rating-for-admin'><h3>Score: {score}%</h3></div>}
                    <div className='all-performance-view'>
                        {data?.KPIs?.map(per => (
                            <div key={per.kpiId} className='performance-view-kpis'>
                                <p className='performance-view-kpi-name'>{per?.kpiName?.toUpperCase()}
                                    <Tooltip target=".info-icon" mouseTrack mouseTrackTop={10} />
                                    <span className='info-icon' data-pr-tooltip={per?.kpiInfo}>
                                        <i className="pi pi-info-circle"></i></span>
                                    {per.target !== null && `(Target: ${per.target})`}
                                    {isAdmin?.toLowerCase() === 'admin' && <span> (Weightage: {per.weightage})</span>}</p>

                                <div className='performance-view-inputs'>

                                    {/* Rating Input ***************/}

                                    <div className='performance-view-ratings-div'>
                                        <p>Rating:</p>
                                        {(reviewMode.toLowerCase() === 'view' && disableButtons && !isOpenAdminReason) ?
                                            (<p>{per.rating}%</p>)
                                            :
                                            (!isNotUpcomingDate(date)) ? (<p></p>) :
                                                (<p><InputNumber className={isValidRating[per.kpiId] ? 'p-invalid' : ''} value={per.rating} onChange={(e) =>
                                                    handleRatings({ value: e.value, employeeCode: data?.EmployeeCode, kpiID: per?.kpiId })
                                                } inputStyle={{ width: '3.5rem' }} tooltip='Value should be from 1 to 99' /> %</p>)
                                        }
                                    </div>

                                    {/* Comment Input ***************/}

                                    <div className='performance-view-reviews-div'>
                                        <p>Comment: </p>
                                        {(reviewMode.toLowerCase() === 'view' && disableButtons && !isOpenAdminReason) ? (<p>{per?.review}</p>)
                                            :
                                            (!isNotUpcomingDate(date)) ? (<p></p>)
                                                : (<InputTextarea value={per?.review} onChange={(e) => handleReviews({ value: e.target.value, employeeCode: data?.EmployeeCode, kpiID: per?.kpiId })} className='comment-input' autoResize={true} />)}
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='performance-view-buttons'>
                        {/* Change Score for admin button */}
                        {(loggedInUserRole !== null && loggedInUserRole === 'admin' && disableButtons)
                            &&
                            (isOpenAdminReason ?
                                <Button label='Cancel Change' onClick={() => setIsOpenAdminReason(false)} style={{ backgroundColor: ThemeColors?.primaryColor, color: ThemeColors?.fontColor, border: 'none' }} /> :
                                <Button label='Change Score' onClick={() => setIsOpenAdminReason(true)} style={{ backgroundColor: ThemeColors?.primaryColor, color: ThemeColors?.fontColor, border: 'none' }} />)}

                        {/* Submit button ************/}
                        {(reviewMode.toLowerCase() === 'view' && !isOpenAdminReason) ? (<Button label='Submit' disabled style={{ backgroundColor: ThemeColors?.primaryColor, color: ThemeColors?.fontColor, border: 'none' }} />)
                            :
                            isOpenAdminReason ? (<Button label='Submit' onClick={handleSubmit} style={{ backgroundColor: ThemeColors?.primaryColor, color: ThemeColors?.fontColor, border: 'none' }} />)
                                :
                                (<Button label='Submit' onClick={handleSubmit} disabled={disableButtons} style={{ backgroundColor: ThemeColors?.primaryColor, color: ThemeColors?.fontColor, border: 'none' }} />)}

                        {/* Save Draft button ************/}
                        {(reviewMode.toLowerCase() === 'view') ?
                            (<Button label='Save Draft' onClick={handleSaveDraft} disabled style={{ backgroundColor: ThemeColors?.primaryColor, color: ThemeColors?.fontColor, border: 'none' }} />)
                            :
                            (<Button label='Save Draft' onClick={handleSaveDraft} disabled={disableButtons} style={{ backgroundColor: ThemeColors?.primaryColor, color: ThemeColors?.fontColor, border: 'none' }} />)}

                        <Button label='Cancel' className='cancel_btn' onClick={handleRoute} style={{ color: ThemeColors?.primaryColor, }} />
                    </div>
                </div>

                <Dialog header={<p>Reason For Change</p>} className='dialog reason-change-dialog' footer={changeResonFooter} visible={isVisibleChangeReson} style={{ width: '50vw', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} contentStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} onHide={() => setIsVisibleChangeReson(false)}>
                    <InputTextarea value={changeReasonVal} onChange={(e) => setChangeReasonVal(e.target.value)} className='reason-input' autoResize={true} />
                </Dialog>
            </Card>
        </div>
    )
}