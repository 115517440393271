export function ShowMinToHourAndMin(timeStr: string, timeInMin: number) {
    if (timeInMin !== undefined) {

        var hours = (timeInMin / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);

        return (
            `${ timeStr } ${ rhours }Hr ${ rminutes } Min `
        )
    }
}