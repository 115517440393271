import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { AppMode } from '../../data/AppMode';
import { AppModeState } from '../../dtos/common/AppModeState';
import colors from '../../shared/json/Colors.json';
import { ISTToDate } from '../../shared/functions/ISTToDate';

interface ser {
    name: string;
    data: any[]
}

interface props {
    data: any[];
    dateType: string;
    date: any
}

export const TopBillingProjChart = (props: props) => {
    let arrayOfVal: any[] = [];
    props.data.forEach(val => arrayOfVal.push(val.projectTimeSeries));

    arrayOfVal = arrayOfVal.flat(2);

    let TrackingDates: string[] = [];

    function getDatesBetween(startDate: Date, endDate: Date) {
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            TrackingDates.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }
    }

    function getFirstDatesOfMonthForGivenYear(date: any) {
        const d = new Date(date.getFullYear(), 0, 1, 1)
        let i = 0;
        while (i <= 11) {
            const date = new Date(d.getFullYear(), d.getMonth() + i, 1)
            TrackingDates.push(ISTToDate(date) as string)
            i++
        }
    }

    function getFirstDatesOfWeekForGivenMonth(inputDate: any) {
        var firstDay = new Date(inputDate.getFullYear(), inputDate.getMonth(), 1);
        firstDay.setDate(firstDay.getDate() - firstDay.getDay() + 1)

        TrackingDates.push(ISTToDate(firstDay) as string)

        for (let i = 0; i < 4; i++) {
            const next = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() - firstDay.getDay() + 8)
            firstDay = next
            TrackingDates.push(ISTToDate(next) as string)
        }
    }

    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);

    const ser: ser[] = []

    if (props.dateType === 'week') {
        getDatesBetween(props.date[0], props.date[1])
    }
    if (props.dateType === 'month') {
        getFirstDatesOfWeekForGivenMonth(props.date)
    }
    if (props.dateType === 'year') {
        getFirstDatesOfMonthForGivenYear(props.date)
    }


    arrayOfVal?.forEach(data => {
        const isPresent: ser | undefined = ser.find(s => s.name.toLowerCase() === data.projectName.toLowerCase());
        if (typeof isPresent === 'undefined') {
            ser.push({
                name: data.projectName,
                data: []
            })
        }
    })


    TrackingDates.forEach(date => {
        ser.forEach(s => {
            const isPresent: any = arrayOfVal?.find(data => data.startTime === date && s.name.toLowerCase() === data.projectName.toLowerCase());

            if (typeof isPresent === 'undefined') {
                s.data.push(0)
            }
            else {
                s.data.push((isPresent?.billTimeInSec / 3600).toFixed(2))
            }
        })
    })

    const legendColor: string[] = [];
    ser.forEach(val => legendColor.push(mode?.color as string));
    const legendColorForDates: string[] = [];
    TrackingDates.forEach(val => legendColorForDates.push(mode?.color as string));

    //for month show only date and month series
    if (props.dateType === 'month') {
        let startEndWk: string[] = [];
        TrackingDates.forEach(date => {
            let endWkDate = new Date(date);
            endWkDate = new Date(endWkDate.getFullYear(), endWkDate.getMonth(), endWkDate.getDate() - endWkDate.getDay() + 7);
            startEndWk.push(`${date.slice(5)} to ${ISTToDate(endWkDate).slice(5)}`)
        })

        TrackingDates = startEndWk

    }

    if (props.dateType === 'year') {
        TrackingDates = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }

    const chart: any = {
        series: ser,
        options: {
            chart: {
                type: 'area',
                stacked: false,
                height: 350,
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            colors: colors.colors,
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.45,
                    opacityTo: 0.05,
                    stops: [20, 100, 100, 100]
                },
            },
            xaxis: {
                type: 'category', // Change to category
                categories: TrackingDates,
                labels: {
                    style: {
                        colors: legendColorForDates
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: legendColor
                    }
                }
            },
            tooltip: {
                shared: true,
                theme: 'dark',
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                offsetX: -10,
                labels: {
                    colors: legendColor
                }
            }
        },


    };
    return (
        <ReactApexChart options={chart.options} series={chart.series} type="area" height={350} />
    )
}