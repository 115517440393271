import * as react from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";

interface props {
    rating: number
}

export const RatingsChart = (props: props) => {
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);

    const chart: any = {
        series: [props.rating],
        options: {
            chart: {
                type: 'radialBar',
                width: 50,
                height: 50,
                sparkline: {
                    enabled: true
                }
            },
            colors: [ThemeColors?.primaryColor],
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '50%'
                    },
                    track: {
                        margin: 0
                    },
                    dataLabels: {
                        show: false
                    },
                }
            }
        },
    }



    return (
        <div>
            <ReactApexChart options={chart.options} series={chart.series} type="radialBar" width={150} height={150} />
        </div>
    )
}