import { InputNumber } from "primereact/inputnumber";
import * as react from "react";
import { useEffect, useRef, useState } from "react";
import { TiTick } from "react-icons/ti"
import "../../css/components/employeeBilling.css";
import { Toast } from "primereact/toast";

interface Input {
    isloggedIn: boolean;
    timeInMin: number;
    handleSave: any
}
export const InputTimeDifference = (input: Input) => {
    const [billingInput, setBillingInput] = useState<boolean>(false);
    const [hourVal, setHourVal] = useState<number>(0);
    const [minVal, setMinVal] = useState<number>(0);
    const [billingTime, setBillingTime] = useState(0);
    const toast = useRef<any>(null);
    const validHour = new RegExp('^([0-1]?[0-9]|2[0-3])$');
    const validMin = new RegExp('^[0-9]$|^[1-5][0-9]$');

    const handleSave = async () => {
        let validationErr: string = '';
        if (hourVal === null) {
            setHourVal(0)
        }
        if (minVal === null) {
            setMinVal(0)
        }
        if (!validHour.test(hourVal?.toString())) {
            validationErr = 'Please provide valid hour value';
        }

        if (!validMin.test(minVal?.toString())) {
            validationErr = 'Please provide valid minute value';
        }

        if (!validHour.test(hourVal?.toString()) && !validMin.test(minVal?.toString())) {
            validationErr = 'Please provide valid hour and minute value';
        }

        const timeInMin: number = (hourVal * 60) + minVal;

        if (validHour.test(hourVal?.toString()) && validMin.test(minVal?.toString())) {
            await input.handleSave(timeInMin)
            setBillingTime(timeInMin)
            setBillingInput(false);
        }
        else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: validationErr });
        }
    }

    const handleBillingInput = () => {
        if (input.isloggedIn) {
            setBillingInput(false)
        } else {
            setBillingInput(true)
        }
    }

    const handleTime = () => {
        if (input.timeInMin !== undefined) {

            var hours = (input.timeInMin / 60);
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            setHourVal(rhours);
            setMinVal(rminutes)
        }
    }

    useEffect(() => {
        handleTime()
    }, [billingTime])

    return (
        <div className={billingInput ? 'input-for-billing-time-main' : 'input-for-billing-time'} onClick={handleBillingInput}>
            <Toast ref={toast} />
            <div className="input-for-billing-time-main" >
                {billingInput ? (
                    <div className="input-for-billing-time" >
                        <InputNumber inputStyle={{ width: '3rem' }} size={1} value={hourVal} onValueChange={(e: any) => setHourVal(e.value)} tooltip='Hour will not be more than 23' tooltipOptions={{ position: 'top' }} />Hr
                        <InputNumber inputStyle={{ width: '3rem' }} size={1} value={minVal} onValueChange={(e: any) => setMinVal(e.value)} tooltip="Minute will not be more than 59" tooltipOptions={{ position: 'top' }} />Min
                    </div>

                ) : (<label>{hourVal} Hr : {minVal} Min</label>)}
            </div>
            {billingInput && (<TiTick size={30} onClick={handleSave} style={{ color: 'green' }} />)}
        </div>
    )
}
