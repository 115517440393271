import { ChangeProfileInputDto } from "../../dtos/user/ChangeProfileInputDto";
import { ChangePwdInput } from "../../dtos/user/ChangePwdDto";
import { EmailDto } from "../../dtos/user/EmailDto";
import instance, { get, patch } from "../../utills/BaseService";

export async function changePwd(dispatch: any, input: ChangePwdInput) {
    return await instance.patch('/user/changePassword', input)
}
export async function editProfile(dispatch: any, id: number, input: ChangeProfileInputDto) {
    return await patch(dispatch, '/user/updateUser', id, input)
}
export async function getLoggedInUser(dispatch: any, input: EmailDto) {
    return await get(dispatch, '/user/getOneUser', input)
}
export async function getCurrentUserLoginInfo(dispatch: any) {
    return await get(dispatch, '/user/getCurrentUserLoginInfo', null);
}
export async function logout() {
    return await instance.put('/user/logout');
}