import * as react from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  ProSidebarProvider,
  SubMenu,
} from "react-pro-sidebar";
import {
  faArrowUpFromGroundWater,
  faListCheck,
  faBezierCurve,
  faChartLine,
  faFolderPlus,
  faMagnifyingGlassDollar,
  faUserGear,
  faUserTie,
  faUsers,
  faChartColumn,
  faGear,
  faEnvelope,
  faUserGroup,
  faUsersGear,
  faEnvelopeOpen,
  faGears,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";
import "../../css/components/sidebar.css";
import { Link } from "react-router-dom";
import image from "../../images/epms_logo_main.png";
import icon from "../../images/icon.png";
import { setSideBar, setSideBarClicked } from "../../store/slices/sidebarSlice";
import { SideBarState } from "../../dtos/sidebar/SideBarState";
import { useEffect } from "react";

export const ProSidebar = () => {
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const collapsed = useSelector((state: any) => state.sidebar.isOpen);
  const dispatch = useDispatch();
  const role = localStorage.getItem("role") as string;
  const ref = react.useRef<any>(null);
  const isOpen = useSelector((state: SideBarState) => state.sidebar.isOpen);
  const isClicked = useSelector((state: SideBarState) => state.sidebar.isClicked);
  const onItemClick = (path: string) => {
    localStorage.setItem("lastRoute", `/${path}`);
  };
  const localization = useSelector(
    (state: any) => state.localization.localization
  );

  const handleClickOutside = (event: any) => {
    const screenWidth = window.innerWidth;
    const shouldOpenSidebar = screenWidth < 1200;
    if (!shouldOpenSidebar) {
      return false
    }
    else {
      if (!isOpen) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(setSideBar(true));
        }
      }
    }
  };

  react.useEffect(() => {
    const handleMediaQueryChange = (e: MediaQueryListEvent) => {
      dispatch(setSideBar(!e.matches));
    };

    const mediaQuery = window.matchMedia("(min-width: 1199px)");
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Initialize the sidebar state based on the initial media query
    dispatch(setSideBar(!mediaQuery.matches));

    // Cleanup event listener when component unmounts
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };

  }, []);

  useEffect(() => {
    // Attach event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="pro-sidebar-container"
      style={{
        backgroundColor: ThemeColors?.primaryColor,
        color: ThemeColors?.fontColor,
      }}
    >
      <ProSidebarProvider >
        <Sidebar defaultCollapsed={collapsed} className="main-sidebar" ref={ref}>
          <header
            style={{
              backgroundColor: ThemeColors?.primaryColor,
              color: ThemeColors?.fontColor,
            }}
          >
            <img
              src={collapsed ? icon : image}
              className={collapsed ? "sidebar-icon" : "sidebar-image"}
            />
          </header>
          <Menu
            style={{
              backgroundColor: ThemeColors?.primaryColor,
              color: ThemeColors?.fontColor,
            }}
            className="sidebar-menu"
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                if (level === 0)
                  return {
                    color: active
                      ? ThemeColors?.primaryColor
                      : ThemeColors?.fontColor,
                    backgroundColor: active
                      ? "white"
                      : ThemeColors?.secondaryColor,
                    borderRadius: "0.5rem",
                    "&:hover": {
                      backgroundColor: ThemeColors?.fontColor,
                      color: ThemeColors?.primaryColor,
                    },
                  };
                if (level === 1)
                  return {
                    color: disabled ? "red" : ThemeColors?.fontColor,
                    backgroundColor: active
                      ? "#eecef9"
                      : ThemeColors?.secondaryColor,
                    borderRadius: "0.5rem",
                    "&:hover": {
                      backgroundColor: ThemeColors?.fontColor,
                      color: ThemeColors?.primaryColor,
                    },
                  };
              },
            }}
          >
            {/* dashboard */}
            <Link to="/dashboard">
              <MenuItem
                active={window.location.pathname === "/dashboard"}
                onClick={() => onItemClick("dashboard")}
                className="sidebar-menu-item"
                icon={
                  <FontAwesomeIcon icon={faChartLine} className="nav-icon" />
                }
              >
                {localization?.Dashboard || "Dashboard"}
              </MenuItem>
            </Link>

            {/* project */}
            <Link to="/project">
              <MenuItem
                active={window.location.pathname === "/project"}
                onClick={() => onItemClick("project")}
                className="sidebar-menu-item"
                icon={
                  <FontAwesomeIcon icon={faListCheck} className="nav-icon" />
                }
              >
                {localization?.Project || "Project"}
              </MenuItem>
            </Link>

            {/* your team */}
            <Link to="/employee">
              <MenuItem
                active={window.location.pathname === "/employee"}
                className="sidebar-menu-item"
                icon={<FontAwesomeIcon icon={faUsers} className="nav-icon" />}
              >
                {localization?.YourTeam || "Your Team"}
              </MenuItem>
            </Link>

            {/* your performance */}
            <Link to="/yourPerformance">
              <MenuItem
                active={window.location.pathname === "/yourPerformance"}
                className="sidebar-menu-item"
                icon={<FontAwesomeIcon icon={faChartLine} className="nav-icon" />}
              >
                {localization?.YourPerformance || "Your Performance"}
              </MenuItem>
            </Link>

            {/* project billing */}
            <Link to="/projectBilling">
              <MenuItem
                active={window.location.pathname === "/projectBilling"}
                className="sidebar-menu-item"
                icon={
                  <FontAwesomeIcon icon={faFolderPlus} className="nav-icon" />
                }
              >
                {localization?.ProjectBilling || "Project Billing"}
              </MenuItem>
            </Link>

            {/* employee billing */}
            <Link to="/employeeBilling">
              <MenuItem
                active={window.location.pathname === "/employeeBilling"}
                className="sidebar-menu-item"
                icon={<FontAwesomeIcon icon={faUserTie} className="nav-icon" />}
              >
                {localization?.EmployeeBilling || "Employee Billing"}
              </MenuItem>
            </Link>

            {/* KPI */}
            {role?.toLowerCase() === 'admin' && <Link to="/kpi">
              <MenuItem
                active={window.location.pathname === "/kpi"}
                className="sidebar-menu-item"
                icon={
                  <FontAwesomeIcon
                    icon={faArrowUpFromGroundWater}
                    className="nav-icon"
                  />
                }
              >
                {localization?.KPI || "KPI"}
              </MenuItem>
            </Link>}

            {/* KPI Mappings */}
            {role?.toLowerCase() === 'admin' &&
              <SubMenu
                label={localization?.KPIMappings || "KPI Mappings"}
                active={window.location.pathname.endsWith("ppings")}
                icon={
                  <FontAwesomeIcon icon={faBezierCurve} className="nav-icon" />
                }
                className="sidebar-submenu-item">
                <div
                  style={{
                    backgroundColor: ThemeColors?.primaryColor,
                    color: ThemeColors?.fontColor,
                  }}
                  className="sidebar-submenu-contents"
                >
                  <Link to="/kpiMappings">
                    <MenuItem
                      active={window.location.pathname === "/kpiMappings"}
                      className="sidebar-menu-item"
                      icon={
                        <FontAwesomeIcon icon={faUsersGear} className="nav-icon" />
                      }
                    >
                      {localization?.RoleMappings || "Role Mappings"}
                    </MenuItem>
                  </Link>
                  <Link to="/userMappings">
                    <MenuItem
                      active={window.location.pathname === "/userMappings"}
                      className="sidebar-menu-item"
                      icon={
                        <FontAwesomeIcon icon={faUserGroup} className="nav-icon" />
                      }
                    >
                      {localization?.UserMappings || "User Mappings"}
                    </MenuItem>
                  </Link>
                </div>
              </SubMenu>
            }

            {/* Performance */}
            <Link to="/performance">
              <MenuItem
                active={window.location.pathname.startsWith("/performance")}
                className="sidebar-menu-item"
                icon={
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassDollar}
                    className="nav-icon"
                  />
                }
              >
                {localization?.RatingsReviews || "Ratings & Reviews"}
              </MenuItem>
            </Link>

            {/* Performance Report*/}
            <Link to="/ratingsReport">
              <MenuItem
                active={window.location.pathname.startsWith("/ratingsReport")}
                className="sidebar-menu-item"
                icon={
                  <FontAwesomeIcon icon={faChartSimple} className="nav-icon" />
                }
              >
                {localization?.PerformanceReport || "Performance Report"}
              </MenuItem>
            </Link>

            {/* Report */}
            <Link to="/report">
              <MenuItem
                active={window.location.pathname === "/report"}
                className="sidebar-menu-item"
                icon={
                  <FontAwesomeIcon icon={faChartColumn} className="nav-icon" />
                }
              >
                {localization?.Report || "Report"}
              </MenuItem>
            </Link>

            {/* Settings */}
            {role?.toLowerCase() === 'admin' &&
              <SubMenu
                active={window.location.pathname.endsWith("ttings")}
                className="sidebar-submenu-item"
                icon={<FontAwesomeIcon icon={faGear} className="nav-icon" />}
                label={localization?.Settings || "Settings"}
              >
                <div
                  style={{
                    backgroundColor: ThemeColors?.primaryColor,
                    color: ThemeColors?.fontColor,
                  }}
                  className="sidebar-submenu-contents"
                >
                  {/* Mail Settings */}
                  <Link to="/mailSettings">
                    <MenuItem
                      active={window.location.pathname === "/mailSettings"}
                      style={{ paddingTop: "0", paddingRight: "0.2rem" }}
                      className="sidebar-menu-item"
                      icon={
                        <FontAwesomeIcon icon={faEnvelope} className="nav-icon" />
                      }
                    >
                      {localization?.MailSettings || "Mail Settings"}
                    </MenuItem>
                  </Link>

                  {/* Mail Template  */}
                  <Link to="/mailTemplateSettings">
                    <MenuItem
                      active={window.location.pathname === "/mailTemplateSettings"}
                      style={{ paddingRight: "0.8rem 0.1rem 0 0.2rem" }}
                      className="sidebar-menu-item"
                      icon={
                        <FontAwesomeIcon icon={faEnvelopeOpen} className="nav-icon" />
                      }
                    >
                      {localization?.MailTemplate || "Mail Template"}
                    </MenuItem>
                  </Link>

                  {/* Mail Template  */}
                  <Link to="/projectSettings">
                    <MenuItem
                      active={window.location.pathname === "/projectSettings"}
                      style={{ paddingRight: "0.8rem 0.1rem 0 0.2rem" }}
                      className="sidebar-menu-item"
                      icon={
                        <FontAwesomeIcon icon={faGears} className="nav-icon" />
                      }
                    >
                      {localization?.ProjectSettings || "Project Settings"}
                    </MenuItem>
                  </Link>

                  {/* Application Settings */}
                  <Link to="/applicationSettings">
                    <MenuItem
                      active={window.location.pathname === "/applicationSettings"}
                      style={{ paddingRight: "0.8rem 0.1rem 0 0.2rem" }}
                      className="sidebar-menu-item"
                      icon={
                        <FontAwesomeIcon icon={faUserGear} className="nav-icon" />
                      }
                    >
                      {localization?.AppSettings || "App Settings"}
                    </MenuItem>
                  </Link>

                  {/* Audit log */}
                  <Link to="/auditLog">
                    <MenuItem
                      active={window.location.pathname === "/auditLog"}
                      style={{ paddingRight: "0.8rem 0.1rem 0 0.2rem" }}
                      className="sidebar-menu-item"
                      icon={
                        <FontAwesomeIcon icon={faUserGear} className="nav-icon" />
                      }
                    >
                      {localization?.AuditLog || "Audit Log"}
                    </MenuItem>
                  </Link>
                </div>
              </SubMenu>}
          </Menu>
        </Sidebar>
      </ProSidebarProvider>


    </div>
  );
};
