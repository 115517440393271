import { createSlice } from "@reduxjs/toolkit";

interface State {
    islogin: boolean,
}
interface Action {
    type: string,
    payload: State
}

const loginSlice = createSlice({
    name: "loginSlice",
    initialState: {
        islogin: true,
    },
    reducers: {
        setLogin(state: State, action: Action) {
            state.islogin = action.payload.islogin;
        }
    }
});

export const { setLogin } = loginSlice.actions;
export default loginSlice.reducer;