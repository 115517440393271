import { createSlice } from "@reduxjs/toolkit";

interface State {
    isOpen: boolean;
    isClicked: boolean;
}
interface Action {
    type: string,
    payload: boolean
}

const themeSlice = createSlice({
    name: "SidebarSlice",
    initialState: {
        isOpen: false,
        isClicked: true
    },
    reducers: {
        setSideBar(state: State, action: Action) {
            state.isOpen = action.payload
        },
        setSideBarClicked(state: State, action: Action) {
            state.isClicked = action.payload
        }
    }
});

export const { setSideBar, setSideBarClicked } = themeSlice.actions;
export default themeSlice.reducer;