// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-btn {
    display: flex;
    justify-content: center;
}

.login-loading-bar {
    position: absolute;
    left: 74.8%;
    top: 47%;
    z-index: 5000000;
    transform: translate(-50%, -50%);
}

.login-input {
    width: 100%;
    padding: 1rem 0.9rem;
    font-size: 1rem;
    border: 1px solid #b7b1b1;
    border-radius: 5px;
    outline: none;
}

.password-box {
    position: relative;
}

.password-box>span {
    position: absolute;
    top: 18.5px;
    right: 19.3px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/components/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,eAAe;AACnB","sourcesContent":[".login-btn {\n    display: flex;\n    justify-content: center;\n}\n\n.login-loading-bar {\n    position: absolute;\n    left: 74.8%;\n    top: 47%;\n    z-index: 5000000;\n    transform: translate(-50%, -50%);\n}\n\n.login-input {\n    width: 100%;\n    padding: 1rem 0.9rem;\n    font-size: 1rem;\n    border: 1px solid #b7b1b1;\n    border-radius: 5px;\n    outline: none;\n}\n\n.password-box {\n    position: relative;\n}\n\n.password-box>span {\n    position: absolute;\n    top: 18.5px;\n    right: 19.3px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
