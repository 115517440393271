import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import * as React from "react";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { getAllFilterRoles, getAllRole } from "../../service/role/RoleService";
import { getAllActiveKpi } from "../../service/kpi/kpiService";
import { KPIItemsDto } from "../../dtos/kpi/KPIItemsDto";
import { CreateKPIMappings, KPIInnputs } from "../../dtos/kpiMappings/CreateKPIMappingsDto";
import { AllRolesDto } from "../../dtos/kpiMappings/AllRolesDto";
import { createKpiMappings, deleteKpiMappingsByRoleName, getAllKpiMappings } from "../../service/kpiMappings/kpiMappingsService";
import { Toast } from 'primereact/toast';
import { GetAllKpiMappingsDto, Kpis } from "../../dtos/kpiMappings/GetAllKpiMappingsDto";
import "../../css/components/kpiMappings.css";
import { Chip } from 'primereact/chip';
import { Slider } from "primereact/slider";
import { InputNumber } from "primereact/inputnumber";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export const KpiMappings = () => {

    // #region all Variables

    const [searchText, setSearchText] = useState<string>('');
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const [kpiMappingsDialog, setKpiMappingsDialog] = React.useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<AllRolesDto | null>();
    const [roles, setRoles] = useState<AllRolesDto[]>([]);
    const [allKPI, setAllKpi] = useState<KPIItemsDto[]>([]);
    const [selectedKPI, setSelectedKPI] = useState<any[] | null>(null);
    const [data, setData] = useState<GetAllKpiMappingsDto[]>([]);
    const [showDropdown, setShowDropdown] = useState<boolean>(true);
    const [allRoles, setAllRoles] = useState<AllRolesDto[]>([]);
    const [labelRoleData, setLabelRoleData] = useState<string>('');
    const dispatch = useDispatch();
    const toast = useRef<any>(null);
    const [sliderValues, setSliderValues] = useState(allKPI.map(kp => 50));
    const [targetValues, setTargetValues] = useState(allKPI.map(kp => 50));
    const [errorIndex, setErrorIndex] = useState<number[]>([])
    const validWeightage = new RegExp('^(?:[0-9][0-9]?|100)$');
    const [reqRoleErr, setReqRoleErr] = useState(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [searckKPI, setSearchKPI] = useState<string>('');
    const [kpis, setKpis] = useState<KPIItemsDto[]>([]);
    const [kpiList, setKpiList] = useState<KPIItemsDto[]>([]);
    const [allKpiToShow, setAllKpiToShow] = useState<KPIItemsDto[]>([]);
    const [selectKpiDialog, setSelectKpiDialog] = useState<boolean>(false);
    const localization = useSelector((state: any) => state.localization.localization);

    //#endregion

    // #region all Functions

    const getAllData = async () => {
        const data = await getAllKpiMappings(dispatch, null);
        const searchedData = data?.filter((sData: GetAllKpiMappingsDto) => {
            const matchingKPIs = sData.Kpis.some((kpData: Kpis) => {
                return kpData?.KPI.Name?.toLowerCase()?.includes(searchText);
            });
            return matchingKPIs || sData?.RoleName?.toLowerCase()?.includes(searchText);
        });
        setData(searchedData);
    };

    const getAllRoles = async () => {
        const filteredRoles = await getAllFilterRoles(dispatch);
        setRoles(filteredRoles);
        const AllRoles: AllRolesDto[] = await getAllRole(dispatch);
        setAllRoles(AllRoles);
    };

    const getAllKpis = async () => {
        const res: KPIItemsDto[] = await getAllActiveKpi(dispatch, null);
        setAllKpi(res);
    };

    const handleSave = async () => {

        const kpiInputs: KPIInnputs[] = [];
        const isEmptySliderVal: boolean[] = [];
        const isEmptyTargetVal: boolean[] = [];

        const input: KPIInnputs[] = [];
        allKpiToShow?.forEach((kpiItems: KPIItemsDto, index: number) => {
            let findSliderVal = sliderValues.find((val, i) => i === index)
            let findTargetVal = targetValues.find((val, i) => i === index)

            //if there is no value in weightage then make it 0 and for messahe inser false in isEmptySliderVal
            if (findSliderVal === undefined) {
                findSliderVal = 0
                isEmptySliderVal.push(false)
            }
            //if there is no value in target then make it 0 and for messahe inser false in isEmptySliderVal
            if (findTargetVal === undefined) {
                findTargetVal = 0
                isEmptySliderVal.push(false)
                isEmptyTargetVal.push(false)
            }

            input.push({
                KPIID: kpiItems.id,
                Weightage: findSliderVal,
                Target: findTargetVal
            })
        })
        input.forEach(val => {
            if (val.Weightage !== null) {
                kpiInputs.push(val)
            }
        })
        const totalWeightage = kpiInputs.reduce((acc, kpi) => {

            return acc + kpi.Weightage
        }, 0);


        if (selectedRole?.name.length && selectedRole?.name.length > 0) {
            setReqRoleErr(false);

            if (kpiInputs.length === 0 && isEdit === true) {
                confirm(selectedRole?.name.toLowerCase())
                return
            }

            if (kpiInputs.length === 0 && isEdit === false) {
                toast.current.show({ severity: 'warn', summary: 'Please Note!', detail: "Please select some kpi and weightage for saving the data.", life: 3000 });
                return
            }

            if (isEmptySliderVal.includes(false)) {
                toast.current.show({ severity: 'warn', summary: 'Please Note!', detail: "For saving the data fill all the weightage value.", life: 5000 });
                return;
            }
            if (isEmptyTargetVal.includes(false)) {
                toast.current.show({ severity: 'warn', summary: 'Please Note!', detail: "For saving the data fill all the target value.", life: 5000 });
                return;
            }

            if (totalWeightage !== 100) {
                toast.current.show({ severity: 'warn', summary: 'Please Note!', detail: "For saving the data the total weightage of kpis should be 100 otherwise you can't save the data.", life: 5000 });
                return
            }

            const createdData: CreateKPIMappings = {
                RoleName: selectedRole?.name.toLowerCase(),
                EmployeeCode: null,
                KpiInputs: kpiInputs
            };

            const isThereZeroVal = createdData?.KpiInputs?.filter(kpIn => kpIn.Weightage === 0);
            const isThereTargetZeroVal = createdData?.KpiInputs?.filter(kpIn => kpIn.Target === 0);

            if (isThereZeroVal.length !== 0) {
                toast.current.show({ severity: 'warn', summary: 'Please Note!', detail: "Weightage of kpi's can't be zero.", life: 4000 });
                return false;
            }
            if (isThereTargetZeroVal.length !== 0) {
                toast.current.show({ severity: 'warn', summary: 'Please Note!', detail: "Target of kpi's can't be zero.", life: 4000 });
                return false;
            }
            await createKpiMappings(dispatch, createdData);
            await getAllData();
            const filteredRoles = await getAllFilterRoles(dispatch);
            setRoles(filteredRoles);
            setSelectedRole(null);
            setKpiMappingsDialog(false);
            setSelectedKPI(null);
            setSliderValues([]);
            setTargetValues([])
            setErrorIndex([]);
            setKpiList([]);
            setSearchKPI('');
            setKpis([]);
            setAllKpiToShow([])
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'You have successfully change kpi mappings!', life: 3000 });
        }
        else {
            setReqRoleErr(true)
            toast.current.show({ severity: 'warn', summary: 'Please Note!', detail: "For saving the data please select a role name.", life: 3000 });
        }
    };

    const handleCreate = async () => {
        setShowDropdown(true);
        setKpiMappingsDialog(true);
    };

    const handleEdit = async (rowData: GetAllKpiMappingsDto) => {
        setIsEdit(true);
        setShowDropdown(false);
        const labelData = allRoles?.find((role: AllRolesDto) => {
            return role.name.toLowerCase() === rowData.RoleName.toLowerCase();
        })
        if (labelData) {
            setSelectedRole(labelData)
            setLabelRoleData(labelData.displayName);
        };
        setKpiMappingsDialog(true);

        rowData.Kpis.find((kpi: Kpis) => {
            const findKpi = allKPI.find(kp => kp.id === kpi.KPI.id);
            if (typeof findKpi !== 'undefined') {
                setAllKpiToShow(prev => {
                    const newKpi = [...prev];
                    newKpi.push(findKpi)
                    return newKpi
                })
            }
        })

        rowData.Kpis.forEach((kpi, index) => {
            setSelectedKPI((prev: any) => {
                // Ensure prev is an array
                const next = Array.isArray(prev) ? [...prev] : [];
                next.push({
                    index: index,
                    kpi: kpi.KPI
                })
                return next
            })
            setSliderValues((prev: any) => {
                // Ensure prev is an array
                const next = Array.isArray(prev) ? [...prev] : [];
                if (kpi.Weightage === null) {
                    next[index] = 0;
                } else {
                    next[index] = kpi.Weightage;
                }
                return next;
            })
            setTargetValues((prev: any) => {
                // Ensure prev is an array
                const next = Array.isArray(prev) ? [...prev] : [];
                if (kpi.Target === null) {
                    next[index] = 0;
                } else {
                    next[index] = kpi.Target;
                }
                return next;
            })
        })
    };

    const hideDialog = () => {
        setSelectedKPI(null);
        setSliderValues([]);
        setTargetValues([]);
        setErrorIndex([])
        setKpiMappingsDialog(false)
        setSelectedRole(null);
        setReqRoleErr(false);
        setIsEdit(false);
        setKpiList([]);
        setSearchKPI('');
        setKpis([]);
        setAllKpiToShow([])
    };

    const handleSliderChange = (index: number, value: any) => {
        if (!validWeightage.test(value)) {
            setErrorIndex(prevErrorIndex => [...prevErrorIndex, index]);
        } else {
            setErrorIndex(prevErrorIndex => prevErrorIndex.filter(val => val !== index));
        }
        const newSliderValues = [...sliderValues];
        newSliderValues[index] = value;
        setSliderValues(newSliderValues);
    };

    const handleTargetSliderChange = (index: number, value: any) => {
        if (!validWeightage.test(value)) {
            setErrorIndex(prevErrorIndex => [...prevErrorIndex, index]);
        } else {
            setErrorIndex(prevErrorIndex => prevErrorIndex.filter(val => val !== index));
        }
        const newTargetValues = [...targetValues];
        newTargetValues[index] = value;
        setTargetValues(newTargetValues);
    };


    const deleteMappings = async (role: string) => {
        const deleteMappings = await deleteKpiMappingsByRoleName(dispatch, role);
        await getAllData();
        setKpiMappingsDialog(false);
        setSelectedKPI(null);
        setSliderValues([]);
        setTargetValues([]);
        setErrorIndex([]);
        setSelectedRole(null);
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'The KPIMapping is deleted successfully!', life: 3000 });
        return deleteMappings
    }

    const reject = () => {
        setTimeout(() => {
            setKpiMappingsDialog(false);
        }, 300);
    }


    const confirm = (role: string) => {
        confirmDialog({
            message: 'If you want to save the KPIMappings without any KPI points then this particular KPI will be deleted. Are you sure you want to proceed?',
            header: 'Please Note!',
            icon: 'pi pi-exclamation-triangle',
            headerStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            style: { width: '350px', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            contentStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            acceptClassName: 'p-button-danger',
            accept: () => deleteMappings(role),
            reject
        });
    };


    const handleSearchKPI = () => {
        const searchedKpis = allKPI.filter(kpi => {
            return kpi.Name.toLowerCase().includes(searckKPI.toLowerCase());
        })
        if (searchedKpis.length === 0) {
            setKpis([]);
            return;
        }
        setKpis(searchedKpis);
    }

    const handleKPIList = (kpi: KPIItemsDto) => {
        setKpiList(prev => {
            const newList = [...prev];
            const existingIndex = newList.findIndex((item: any) => item.id === kpi.id);
            if (existingIndex !== -1) {
                // If kpi exists, remove it
                newList.splice(existingIndex, 1);
                return newList;
            }
            newList.push(kpi)
            return newList
        })
    }

    const handleAddKpi = () => {
        setKpis(allKPI)
        setSelectKpiDialog(true);
    }

    const hideSelectKpiDialog = () => {
        setSelectKpiDialog(false)
        setSearchKPI('')
    }

    const saveKpis = () => {
        setAllKpiToShow(prev => {
            const newList = [...prev];
            kpiList.map(kpis => {
                const existingIndex = newList.findIndex((item: any) => item.id === kpis.id);
                if (existingIndex !== -1) {
                    // If kpi exists, remove it
                    return newList
                }
                newList.push(kpis)
            })

            const defaultWeightageVal = Math.floor(100 / newList.length); // Using Math.floor to ensure whole number
            let remainingWeight = 100; // Initialize remaining weight
            const sliderValues: number[] = []; // Initialize slider values array

            newList.forEach((kp, index) => {
                // Calculate weightage for each KPI
                const weight = index === newList.length - 1 ? remainingWeight : defaultWeightageVal; // Ensure the last item takes the remaining weight
                sliderValues.push(weight); // Push weight to sliderValues
                remainingWeight -= weight; // Update remaining weight
            });

            setSliderValues(sliderValues); // Set slider values

            let targetVal: number[] = []
            newList.forEach(kp => targetVal.push(50))

            setTargetValues(targetVal); // Set target values
            return newList
        });

        setKpiList([]);
        setKpis([]);
        setSearchKPI('');
        setSelectKpiDialog(false)
    }

    const handleDeleteKpiMapp = (kpi: KPIItemsDto) => {
        const kpis = allKpiToShow.filter(kp => {
            return kp.id !== kpi.id
        })

        const defaultWeightageVal = Math.floor(100 / kpis.length); // Using Math.floor to ensure whole number
        let remainingWeight = 100; // Initialize remaining weight
        const sliderValues: number[] = []; // Initialize slider values array

        kpis.forEach((kp, index) => {
            // Calculate weightage for each KPI
            const weight = index === kpis.length - 1 ? remainingWeight : defaultWeightageVal; // Ensure the last item takes the remaining weight
            sliderValues.push(weight); // Push weight to sliderValues
            remainingWeight -= weight; // Update remaining weight
        });

        setSliderValues(sliderValues); // Set slider values
        setTargetValues(sliderValues); // Set slider values

        setAllKpiToShow(kpis)
    }

    //#endregion

    // #region all Template

    const startToolbar = () => {
        return (
            <div>
                <h4>{localization?.KPIMappingWithRole || "KPI Mapping With Role"}</h4>
            </div>
        )
    };

    const endToolbar = () => {
        return (
            <div className="endToolbarPerformance">
                <Button label={localization?.Create || 'Create'} style={{ backgroundColor: ThemeColors?.primaryColor, border: 'none' }} onClick={handleCreate} />
                <InputText type="search" placeholder={localization?.Search || 'Search'} onChange={(e) => {
                    setTimeout(() => {
                        setSearchText(e.target.value)
                    }, 500)
                }} />
            </div>
        )
    };

    const editButtonTemplate = (rowData: any) => {
        return (
            <div className='editDeleteButtonTemplate'>
                <Button label={localization?.Edit || 'Edit'} className="mr-2 editDelButton" style={{ backgroundColor: ThemeColors?.primaryColor }} onClick={() => handleEdit(rowData)} />
            </div>
        );
    };

    const informationBodyTemplate = (data: GetAllKpiMappingsDto) => {
        const rowData = data.Kpis.map((d: Kpis) => {
            return <Chip label={`${d?.KPI?.Name} (${d?.Weightage === null ? 0 : d?.Weightage})`} key={d.KPI.Name} style={{ margin: '2px 5px' }} />
        })
        return rowData;
    }

    const dialogHeader = () => {
        return (
            <>
                <div className="dialogHeader">
                    <h4>{localization?.KPIMappings || "Kpi Mappings"}</h4>
                </div>
                {/* <hr /> */}
            </>
        )
    }

    const dialogFooter = () => {
        return (
            <div className="dialogFooter mt-2 pt-2" style={{ paddingTop: "1rem", backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
                <Button label={localization?.Save || "Save"} onClick={handleSave} style={{ backgroundColor: ThemeColors?.primaryColor, border: 'none' }} />
            </div>
        )
    }

    const handleInputs = () => {
        return (
            allKpiToShow.length > 0 &&
            <table className="kpiMap_table custom_kpiMap_table">
                <thead>
                    <tr>
                        <th></th>
                        <th className="text-center" style={{ width: "120px" }}>Weightage</th>
                        <th className="text-center" style={{ width: "120px" }}>Target(100%)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allKpiToShow?.map((kpi, index) => (
                            <tr>
                                <td>
                                    <div className="kpi-mapp-btn">
                                        <Button
                                            disabled={selectedRole?.name === undefined}
                                            label={kpi.Name}
                                            style={{ backgroundColor: ThemeColors?.primaryColor, border: 'none' }}
                                        // onClick={() => handleKpiButtons(kpi, index)}
                                        />
                                        <i className="pi pi-times-circle cross-kpi-mapp" onClick={() => handleDeleteKpiMapp(kpi)} ></i>
                                    </div>
                                </td>
                                <td>
                                    <div className="weightage-container custom-weightage py-2">
                                        <InputNumber
                                            disabled={selectedRole?.name === undefined}
                                            inputStyle={{ width: '100%', padding: '0.6rem 1rem' }}
                                            value={sliderValues[index]}
                                            onChange={(e) => handleSliderChange(index, e.value)}
                                            className={`w-full ${errorIndex.includes(index) ? 'p-invalid' : ''}`}
                                        />
                                        <Slider
                                            disabled={selectedRole?.name === undefined}
                                            value={sliderValues[index]}
                                            onChange={(e) => handleSliderChange(index, e.value)}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </td>

                                <td>
                                    <div className="target-container py-2">
                                        <InputNumber
                                            disabled={selectedRole?.name === undefined}
                                            inputStyle={{ width: '100%', padding: '0.6rem 1rem' }}
                                            value={targetValues[index]}
                                            onChange={(e) => handleTargetSliderChange(index, e.value)}
                                            className={`w-full ${errorIndex.includes(index) ? 'p-invalid' : ''}`}
                                        />
                                        <Slider
                                            disabled={selectedRole?.name === undefined}
                                            value={targetValues[index]}
                                            onChange={(e) => handleTargetSliderChange(index, e.value)}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody >
            </table >
        )

    }
    const selectKpiDialogHeader = () => {
        return (
            <div>
                <h4>{localization?.SelectTheKPI || "Select the KPI"}</h4>
                {/* <hr /> */}
            </div>
        )
    }

    const selectKpiDialogFooter = () => {
        return (
            <Button className="mt-3 pt-2" label={localization?.SaveKPI || "Save KPI"} onClick={saveKpis} style={{ backgroundColor: ThemeColors?.primaryColor, border: 'none', padding: "0.6rem 1rem" }} />
        )
    }

    //#endregion

    React.useEffect(() => {
        handleSearchKPI()
    }, [searckKPI])

    React.useEffect(() => {
        handleInputs()
    }, [errorIndex, selectedRole])

    React.useEffect(() => {
        getAllData()
        getAllRoles();
        getAllKpis();
    }, [searchText, showDropdown]);

    return (
        <div className="kpimapSec">
            <Toast ref={toast} />
            <ConfirmDialog />
            <Toolbar start={startToolbar} end={endToolbar} style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} className="mb-3" />

            <Card style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
                <DataTable
                    value={data}
                    showGridlines
                    className="performance-table audit-log-table"
                >
                    <Column header={localization?.RoleName || 'Role Name'} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field="RoleName"></Column>
                    <Column header={localization?.Information || 'Information'} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} body={informationBodyTemplate}>

                    </Column>
                    <Column className="col_center actionColumn" header={localization?.Action || 'Action'} body={editButtonTemplate} bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }}></Column>
                </DataTable>
            </Card>

            {/* Dialoge for create  ***********************/}

            <Dialog visible={kpiMappingsDialog} baseZIndex={999} style={{ width: '45rem', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} contentStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} className="KpiMap_tab kpi_create_dialog" onHide={hideDialog} header={dialogHeader} footer={dialogFooter}>
                <div className="field">
                    <label className="font-bold">{localization?.Role || "Role"}</label>
                    {showDropdown ?
                        (<Dropdown value={selectedRole} onChange={(e) => {
                            setSelectedRole(e.value)
                            setReqRoleErr(false)
                        }} options={roles} optionLabel="name" placeholder="Select the role" showClear panelStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} />) : (<label className="lable-role">{labelRoleData}</label>)}
                    {reqRoleErr && <small className="p-error">Please select a role</small>}
                </div>
                <div className="field">
                    <label className="font-bold">KPI Points</label>
                    <div className="add-kpi-box">
                        <p className="kpi-points-note">Note: Total weightage of KPI should be 100 not more than 100 or less than 100.</p>
                        <Button className="add_kpi_btn" label="Add KPI" outlined onClick={handleAddKpi} style={{ backgroundColor: ThemeColors?.fontColor, border: `2px solid ${ThemeColors?.primaryColor}`, color: ThemeColors?.primaryColor, padding: "0.6rem 1rem", width:"6rem", textAlign: "center", margin:"0rem 0.3rem" }} />
                    </div>
                    {handleInputs()}
                </div>
            </Dialog>

            {/* Dialog box for select KPIs **************/}

            <Dialog header={selectKpiDialogHeader} footer={selectKpiDialogFooter} visible={selectKpiDialog} onHide={hideSelectKpiDialog} baseZIndex={999} style={{ width: '25rem', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} contentStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} className="KpiMap_tab add_new_kpi">
                <div className="field">
                    <div className="add-kpi-box">
                        <span className="p-input-icon-left" style={{ width: '100%' }}>
                            <i className="pi pi-search" />
                            <InputText className="search_input" placeholder="Search KPI" value={searckKPI} onChange={(e) => setSearchKPI(e.target.value)} style={{ height: '2.8rem' }} />
                        </span>
                    </div>
                    {kpis.length > 0 ?
                        (<div className="searched-kpis-box">
                            {kpis?.map(kpi => {
                                const isButtonAdded = kpiList.find(list => list.id === kpi.id);
                                const isButtonSelected = allKpiToShow.find(listKpi => kpi.id === listKpi.id)
                                return (
                                    <Button label={kpi.Name} onClick={() => handleKPIList(kpi)} className={isButtonAdded ? 'selectBtn hover:bg-green-600' : 'non-selectBtn hover:bg-gray-600'} style={{ width: 'fit-content', backgroundColor: isButtonSelected && 'green' }} />
                                )
                            })}
                        </div>) :
                        (<div className="searched-kpis-box" style={{ color: '#858383' }}> Sorry! there is no KPI according to this search.</div>)}
                </div>
            </Dialog>
        </div>
    )
}

