export function isNotUpcomingDate(dateString: string, datetype?: string) {
  const currDate = new Date();
  const inputDate = new Date(dateString);

  if (datetype && datetype === 'week' && inputDate.getDate() < currDate.getDate()) {
    return true
  }
  else if (datetype && datetype === 'year' && currDate.getFullYear() <= inputDate.getFullYear()) {
    if (inputDate.getMonth() > 1) {
      return true
    }
    else {
      return false
    }
  }
  else if (currDate.getFullYear() <= inputDate.getFullYear() && currDate.getMonth() <= inputDate.getMonth()) {
    return false;
  } else {
    return true;
  }
}