import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const ProtectedRoute = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const checkUserToken = () => {
        const token = localStorage.getItem("token");
        if (!token || token === 'undefined') {
            setIsLoggedIn(false);
            navigate("/login")
        }
        else {
            setIsLoggedIn(true);
            // Check if there's a stored route, and navigate to it
            const storedRoute = localStorage.getItem('lastRoute');
            if (storedRoute) {
                localStorage.removeItem('lastRoute'); // Remove the stored route
                navigate(storedRoute);
            }
        }
    }

    useEffect(() => {
        checkUserToken()
    }, [])

    useEffect(() => {
        if (isLoggedIn) {
            localStorage.setItem('lastRoute', location.pathname);
        }
    }, [location.pathname, isLoggedIn]);

    return (
        <React.Fragment>
            {isLoggedIn ? props.children : null}
        </React.Fragment>
    )
}

export default ProtectedRoute;
