import { GetAllEmpInputDto } from "../../dtos/employee/GetAllEmpInputDto";
import { get } from "../../utills/BaseService";


export async function getAllEmployeesWithPagination(dispatch: any, details: GetAllEmpInputDto) {
    return await get(dispatch, '/employee/getAllEmployeesWithPagination', details);
};

export async function getSubordinates(dispatch: any) {
    return await get(dispatch, '/employee/getSubordinates', null);
};

export async function getSubordinatesByStatus(dispatch: any, Status: any) {
    return await get(dispatch, '/employee/getSubordinatesByStatus', Status);
};
export async function getSubordinatesNameIDCode(dispatch: any) {
    return await get(dispatch, '/employee/getSubordinatesNameIDCode', null);
};