export function getNextDate(dateString: string) {
    var currentDate = new Date(dateString);
    var nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);

    var year = nextDate.getFullYear();
    var month = String(nextDate.getMonth() + 1).padStart(2, '0');
    var day = String(nextDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}