import { CreateKpiDetailsDto } from "../../dtos/kpi/CreateKpiDetailsDto";
import instance, { del, get, patch, post } from "../../utills/BaseService";

export async function getAllPaginatedKpi(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/kpi', input);
};
export async function getAllActiveKpi(dispatch: any, input: any): Promise<any> {
   return await get(dispatch, '/kpi/getAllActiveKpi', input);
};
export async function createKpi(dispatch: any, details: CreateKpiDetailsDto): Promise<CreateKpiDetailsDto[]> {
   return await post(dispatch, '/kpi', details);
};

export async function updateKpi(dispatch: any, param: number, details: CreateKpiDetailsDto): Promise<CreateKpiDetailsDto[]> {
   return await patch(dispatch, '/kpi', param, details);
};

export async function delKpi(dispatch: any, param: number): Promise<CreateKpiDetailsDto[]> {
   return await del(dispatch, '/kpi', param);
};

export async function getAllKpi(dispatch: any): Promise<any> {
   return await get(dispatch, '/kpi/getAllKpi', null);
};




