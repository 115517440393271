// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-settings-header {
  padding-bottom: 0.4rem;
}

.all-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.input-groups {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 49.2%;
}

.email-settings-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-input-groups {
  display: flex;
  gap: 1rem;
}

.check-groups {
  display: flex;
  gap: 9.4px;
}

.dark .mail-input {
  background-color: #1b2531;
  color: white;
}

.email-settings-btn {
  display: flex;
  gap: 0.5rem;
}

@media screen and (max-width: 1280px) {
  .input-groups {
    width: 49%;
  }
}

@media screen and (max-width: 991px) {
  .input-groups {
    width: 100%;
  }

  .email-settings-footer .input-groups {
    width: 75%;
  }

  .credential_btn {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .email-settings-footer .input-groups {
    width: 68%;
  }
}

@media screen and (max-width: 575px) {
  .email-settings-footer {
    flex-direction: column;
    gap: 1rem;
  }

  .email-settings-footer .input-groups {
    width: 100%;
  }

  .credential_btn {
    width: 220px;
  }
}`, "",{"version":3,"sources":["webpack://./src/css/components/emailInputs.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".email-settings-header {\n  padding-bottom: 0.4rem;\n}\n\n.all-inputs {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 1rem;\n}\n\n.input-groups {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  width: 49.2%;\n}\n\n.email-settings-footer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.check-input-groups {\n  display: flex;\n  gap: 1rem;\n}\n\n.check-groups {\n  display: flex;\n  gap: 9.4px;\n}\n\n.dark .mail-input {\n  background-color: #1b2531;\n  color: white;\n}\n\n.email-settings-btn {\n  display: flex;\n  gap: 0.5rem;\n}\n\n@media screen and (max-width: 1280px) {\n  .input-groups {\n    width: 49%;\n  }\n}\n\n@media screen and (max-width: 991px) {\n  .input-groups {\n    width: 100%;\n  }\n\n  .email-settings-footer .input-groups {\n    width: 75%;\n  }\n\n  .credential_btn {\n    justify-content: center;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .email-settings-footer .input-groups {\n    width: 68%;\n  }\n}\n\n@media screen and (max-width: 575px) {\n  .email-settings-footer {\n    flex-direction: column;\n    gap: 1rem;\n  }\n\n  .email-settings-footer .input-groups {\n    width: 100%;\n  }\n\n  .credential_btn {\n    width: 220px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
