import { Button } from "primereact/button";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ErrorImg from '../../images/Oops_Error.png';
import '../../css/components/unhandledError.css';
import { useSelector } from "react-redux";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";

export const UnhandledError = () => {
    const navigate = useNavigate();
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);

    const handleBackTo = () => {
        localStorage.clear();
        navigate('/login');
        window.location.reload()
    }

    const handleRefresh = () => {
        window.location.reload()
    }

    return (
        <div className="unhandled-err">
            <img src={ErrorImg} alt="Error!" />
            <p>Oops! Something is wrong with the application. Please refresh the page or back to login page for better result.</p>
            <div className="unhandled-err-btn">
                <Button label="Refresh" onClick={handleRefresh} style={{ backgroundColor: ThemeColors?.primaryColor, border: 'none' }} />
                <Button label="Back to login" onClick={handleBackTo} style={{ backgroundColor: ThemeColors?.primaryColor, border: 'none' }} />
            </div>
        </div>
    )
}