// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page{
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.loader{
    flex: 5 1;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/css/pages/home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".home-page{\n    display: flex;\n    height: 100vh;\n    overflow: hidden;\n}\n\n.loader{\n    flex: 5;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
