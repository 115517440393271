//get previous first and last day of week from date array function
export function GetPrevFirstAndLastDayOfWeek(curr: any[]) {
    const firstDay = new Date(curr[0].getFullYear(), curr[0].getMonth(), curr[0].getDate() - curr[0].getDay() - 6);
    const lastDay = new Date(curr[1].getFullYear(), curr[1].getMonth(), curr[1].getDate() -7);


    return {
        first: firstDay,
        last: lastDay
    };
}