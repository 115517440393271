import { createSlice } from "@reduxjs/toolkit";

interface State {
    isloading: boolean,
}
interface Action {
    type: string,
    payload: State
}

const loadingSlice = createSlice({
    name: "loadingSlice",
    initialState: {
        isloading: false,
    },
    reducers: {
        showLoader(state:State) {
            state.isloading = true;
        },
        hideLoader(state:State) {
            state.isloading = false
        }
    }
});

export const { showLoader, hideLoader } = loadingSlice.actions;
export default loadingSlice.reducer;