import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
    name:"UserSlice",
    initialState:{
        userDetails:{
            user: JSON.parse(localStorage.getItem("user") || '{}'),
            loading:false,
            error:null,
        }
    },
    reducers:{
        loginStart(state,action){
            return {...state,
                user:null,
                loading:true,
                error:null
            }
        },
        loginSuccess(state,action){
            return{...state,
                user:localStorage.setItem("user",JSON.stringify(action.payload)),
                loading:false,
                error:null
            }
        },
        loginFailed(state,action){
            return{...state,
                user:null,
                loading:false,
                error:action.payload
            }
        }
    }
})

export const {loginStart,loginSuccess,loginFailed} = userSlice.actions;
export default userSlice.reducer;