import { EmailDto } from "../../dtos/user/EmailDto";
import { GetOneUserDto } from "../../dtos/user/GetOneUserDto";
import { getLoggedInUser } from "../../service/user/userService";

export async function LoggedInUserData(dispatch: any):Promise<GetOneUserDto> {
    const loggedInUser: string | null = localStorage.getItem('user');
    const userData = JSON.parse(loggedInUser as string);
    const input: EmailDto = {
        email: userData.Email
    }
    const data: GetOneUserDto = await getLoggedInUser(dispatch, input);
    return data;
}