import { Button } from 'primereact/button'
import React from 'react';
import { useDispatch } from 'react-redux';
import { changeTheme } from '../../store/slices/themeSlice';
import { UpdateSettings } from '../../dtos/common/UpdateSettingsDto';
import { updateSettings } from '../../service/common/commonService';

interface ThemeProps {
    themeName: string,
    buttonColor: string
}

export const ThemeButton: React.FC<ThemeProps> = (Theme: ThemeProps) => {
    const dispatch = useDispatch();
    const themeChangeHandler = async () => {
        const updateSettingsValue: UpdateSettings = {
            Key: 'Theme',
            Value: Theme.themeName
        }
        await updateSettings(dispatch, updateSettingsValue)
        dispatch(changeTheme({
            themeName: Theme.themeName,
            appMode: ''
        }));
    }

    return (
        <div>
            <Button onClick={themeChangeHandler} className='theme-button' style={{ backgroundColor: Theme.buttonColor }} />
        </div>
    )
}
