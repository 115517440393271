export const AppMode = [
    {
        name:'light',
        backgroundPrimaryColor:'#f3f4f7',
        backgroundSecondaryColor:'#ffffff',
        color:'#2C3333'
    },
    {
        name:'dark',
        backgroundPrimaryColor:'#1b2531',
        backgroundSecondaryColor:'#273143',
        color:'white'
    }
]