export const ISTToDate = (date: Date[] | Date) => {
    if (Array.isArray(date)) {
        const dateRes: string[] = [];
        date.forEach((dt: any) => {
            const year = dt.getFullYear();
            const month = String(dt.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const day = String(dt.getDate()).padStart(2, '0');
            const res = `${year}-${month}-${day}`
            dateRes.push(res)
        })
        return dateRes;
    }
    else {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const dateStr = `${year}-${month}-${day}`;
        return dateStr
    }

}