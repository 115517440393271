import { createSlice } from "@reduxjs/toolkit";

interface State {
    themeName: string,
    appMode:string
}
interface Action {
    type: string,
    payload: State
}

const themeSlice = createSlice({
    name: "ThemeSlice",
    initialState: {
        themeName: 'violet',
        appMode: 'light'
    },
    reducers: {
        changeTheme(state: State, action: Action) {
            state.themeName = action.payload.themeName
            localStorage.setItem('Theme-Name', JSON.stringify({
                themeName: action.payload.themeName
            }))
        },
        changeMode(state:State,action:Action){
            state.appMode = action.payload.appMode
            localStorage.setItem('App-Mode', JSON.stringify({
                appMode: action.payload.appMode
            }))
        }
    }
});

export const { changeTheme , changeMode} = themeSlice.actions;
export default themeSlice.reducer;