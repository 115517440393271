import * as react from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import { editProfile } from "../../service/user/userService";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { ChangeProfileInputDto } from "../../dtos/user/ChangeProfileInputDto";
import '../../css/components/editProfile.css';
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { AiOutlinePlus } from "react-icons/ai";
import { Toast } from 'primereact/toast';
import { LoggedInUserData } from "../../shared/functions/LoggedInUserData";
import { getProfilePicByEmpCode } from "../../service/profilePicture/profilePictureService";
import { setProfilePic } from "../../store/slices/profilePicSlice";
import { Image } from 'primereact/image';


export const EditProfile = () => {
    //#region all Variables

    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [phoneNum, setPhoneNum] = useState<string>('');
    const [id, setId] = useState<number>(0);
    const [email, setEmail] = useState<string>('');
    const [empCode, setEmpCode] = useState<string>('');
    const [jobTitle, setJobTitle] = useState<string>('');
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const localization = useSelector((state: any) => state.localization.localization);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const toast = useRef<any>(null);
    const [profPic, setProfPic] = useState<string>('');

    //#endregion

    //#region all Functions

    const handleLoggedInData = async () => {
        const data = await LoggedInUserData(dispatch);
        const input = {
            empCode: data.EmployeeCode
        }
        const profilePic = await getProfilePicByEmpCode(dispatch, input)
        if (profilePic === '') {
            setProfPic('https://cdn-icons-png.flaticon.com/512/3135/3135715.png');
        } else {
            setProfPic(`data:image/png;base64,${profilePic}`);
        }
        setEmail(data?.Email);
        setFirstName(data?.FirstName);
        setLastName(data?.LastName);
        setEmail(data?.Email);
        setEmpCode(data?.EmployeeCode)
        setId(data?.id);
        if (data.JobTitle !== null) {
            setJobTitle(data?.JobTitle);
        }
        if (data.PhoneNumber !== null) {
            setPhoneNum(data?.PhoneNumber)
        }
    }

    const handleProfilePic = (e: any) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfPic(e?.target?.result as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    }

    const handleSave = async (input: ChangeProfileInputDto) => {
        const editPic = await editProfile(dispatch, id as number, input);
        if (editPic[0] === 1) {
            toast.current.show({severity:'success', summary: 'Success', detail:'You have successfully update you profile picture!', life: 3000});
        }
        dispatch(setProfilePic(input.ProfilePic))
    }


    //#endregion

    react.useEffect(() => {
         handleLoggedInData();
    }, [])

    return (
        <Card style={{ backgroundColor: mode?.backgroundSecondaryColor }}>
            <Toast ref={toast} />
            <div className="edit-profile" style={{ color: mode?.color }}>
                <h2>Edit Profile</h2>
                <div className="image">
                    <label htmlFor='input-file' className="image-label">
                        <Image src={profPic} alt="profilepic" imageClassName="profile-pic" />
                        <AiOutlinePlus size={40} className="plus-icon" />
                        <input id='input-file' type="file" accept="image/png, image/gif, image/jpeg" onChange={handleProfilePic} style={{ display: 'none' }} />
                    </label>
                </div>
                <div className="usernames">
                    <div className="input-label">
                        <label>First Name</label>
                        <InputText disabled value={firstName || ''} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <div className="input-label">
                        <label>Last Name</label>
                        <InputText disabled value={lastName || ''} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                </div>
                <div className="email-add input-label">
                    <label>Email Address</label>
                    <InputText disabled value={email} />
                </div>
                <div className="emp-id input-label">
                    <label>Employee ID</label>
                    <InputText disabled value={empCode} />
                </div>
                <div className="job-title input-label">
                    <label>Job Title</label>
                    <InputText disabled value={jobTitle} />
                </div>
                <div className="phn-num input-label">
                    <label>Phone Number</label>
                    <InputText disabled value={phoneNum || ''} onChange={(e) => setPhoneNum(e.target.value)} />
                </div>
                <Button label="Save" onClick={() => handleSave({
                    EmployeeCode: empCode,
                    FirstName: firstName,
                    LastName: lastName,
                    UserName: firstName + ' ' + lastName,
                    ProfilePic: profPic,
                    PhoneNumber: phoneNum,
                })} style={{ backgroundColor: ThemeColors?.primaryColor }} className="edit-prof-save-btn mt-2 mb-md-0 mb-2" />
            </div>
        </Card>
    )
}