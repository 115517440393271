import * as React from "react";
import PageNotFoundImg from '../../images/PageNotFound.png';
import '../../css/components/unhandledError.css';

export const PageNotFound = ()=>{

    return(
        <div className="page-not-found">
            <img src={PageNotFoundImg} alt="pageNotFound" />
        </div>
    )
}