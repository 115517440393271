import { createSlice } from "@reduxjs/toolkit";
import { MainPerformanceDto } from "../../dtos/performance/MainPerformanceDataDto";

interface State {
    mode: string,
    date: any,
    data: MainPerformanceDto | {},
    roleName: string,
}
interface Action {
    type: string,
    payload: State
}


const performanceViewInputSlice = createSlice({
    name: "performanceViewInputSlice",
    initialState: {
        mode: 'view',
        date: undefined,
        data: {},
        roleName: '',
    },
    reducers: {
        setInputs(state: State, action: Action) {
            state.mode = action.payload.mode;
            state.date = action.payload.date;
            state.data = action.payload.data;
            state.roleName = action.payload.roleName;
            return state
        },
    }
});

export const { setInputs } = performanceViewInputSlice.actions;
export default performanceViewInputSlice.reducer;