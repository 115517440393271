import { createSlice } from "@reduxjs/toolkit";

interface State {
    path: string,
}
interface Action {
    type: string,
    payload: State
}

const routePathSlice = createSlice({
    name: "routePathSliceSlice",
    initialState: {
        path: '/performance',
    },
    reducers: {
        setPath(state: State, action: Action) {
            state.path = action.payload.path;
        }
    }
});

export const { setPath } = routePathSlice.actions;
export default routePathSlice.reducer;