
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import { useEffect, useRef, useState } from "react";
import '../../css/components/mailTemplate.css';
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { findSettingsByKey, updateOrCreateByKey } from "../../service/common/commonService";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { MailTempPreview } from "../subComponents/MailTempPreview";
import { AppMode } from "../../data/AppMode";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Mail_Template } from "../../shared/constant/StarValue";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export const MailTemplate = () => {
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find((th) => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find((md) => md.name === modeName);
    const [showPreview, setShowPreview] = useState(false);
    const dispatch = useDispatch();
    const toast = useRef<any>(null);
    const [text, setText] = useState('');
    const [initialized, setInitialized] = useState(false)
    const localization = useSelector(
        (state: any) => state.localization.localization
    );

    const handleText = async () => {
        const mailTemp = await findSettingsByKey(dispatch, { key: Mail_Template });
        setText(mailTemp?.Value);
        setInitialized(true)
    }

    const addEmpName = () => {
        const userNamePlaceholder = "#empName#";
        setText((prevText) => {
            // Find the last occurrence of </p> in the string
            const lastPTagIndex = prevText.lastIndexOf('</p>');

            // Concatenate the userNamePlaceholder after the last </p> tag
            const newText =
                lastPTagIndex !== -1
                    ? prevText.slice(0, lastPTagIndex) + ` ${userNamePlaceholder}</p>`
                    : `${prevText} ${userNamePlaceholder}`;

            return newText;
        });
    }
    const addAllSubordinates = () => {
        const subordinatesPlaceholder = "#allSubordinates#";
        setText((prevText) => {
            // Find the last occurrence of </p> in the string
            const lastPTagIndex = prevText.lastIndexOf('</p>');

            // Concatenate the userNamePlaceholder after the last </p> tag
            const newText =
                lastPTagIndex !== -1
                    ? prevText.slice(0, lastPTagIndex) + ` ${subordinatesPlaceholder}</p>`
                    : `${prevText} ${subordinatesPlaceholder}`;

            return newText;
        });

    }

    const handlePreviewBtn = () => {
        setShowPreview(true);
    }

    const handleSaveBtn = () => {
        confirmDialog({
            message: 'Are you sure you want change the mail template?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            headerStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            style: { width: '350px', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            contentStyle: { backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color },
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    }

    const accept = async () => {
        const isPresentEmpName = text.includes("#empName#")
        const isPresentSub = text.includes("#allSubordinates#")

        if (!isPresentEmpName || !isPresentSub) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please add the variables(employee and subordinates)!', life: 3000 });
            return;
        }
        await updateOrCreateByKey(dispatch, { Key: Mail_Template, Value: text });
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'You have successfully update the mail template!'})
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected the process', life: 3000 });
    }


    useEffect(() => {
        handleText()
    }, [])

    return (
        <Card className="" style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} >
            <ConfirmDialog />
            <Toast ref={toast} />
            <div className='email-settings-header'>
                <h3>{localization?.MailTemplate || "Mail Template "}</h3>
            </div>

            <div className="mail-template-buttons">
                <div className="add-content-buttons">
                    <Button label="Add Employee Name Field" onClick={addEmpName} />
                    <Button label="Add All Subordinates" onClick={addAllSubordinates} />
                </div>
                <div>
                    <Button icon="pi pi-eye" onClick={handlePreviewBtn} />
                </div>
            </div>
            {initialized && <Editor value={text} onTextChange={(e: any) => setText(e.htmlValue)} style={{ height: '320px', backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} className="editor" />}

            <Button className="mail-template-save-btn" style={{ width: "5rem" }} label="Save" onClick={handleSaveBtn} />

            <Dialog header="Mail Body" className="mail-template-dialog" style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} contentStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} visible={showPreview} onHide={() => setShowPreview(false)}>
                <div id="mailTemp"></div>
                <MailTempPreview temp={text} />
            </Dialog>
        </Card>
    )
}