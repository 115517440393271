import * as react from "react";
import ReactApexChart from "react-apexcharts";
import { ProjBillTimeForReportSerDto } from "../../dtos/employeeBilling/ProjBillTimeForReportSerDto";
import { useEffect, useState } from "react";
import { TotalTrackAndBillTimeByEmpDto } from "../../dtos/employeeBilling/TotalTrackAndBillTimeByEmpDto";
import colors from '../../shared/json/Colors.json';

export const EmployeeTrackTimeForReportChart = (props: any) => {

    const Data: TotalTrackAndBillTimeByEmpDto[] = props?.data;
    const [showChart, setShowChart] = useState(false);
    const [chart, setChart] = useState<any>(null);
    const ser: ProjBillTimeForReportSerDto[] = [];

    const handleData = () => {
        Data?.forEach(emp => {
            const num = typeof emp?.TrackTimeInMinute === 'undefined' ? '0' : Number(emp?.TrackTimeInMinute as number / 60).toFixed(2)
            ser.push({
                x: emp?.EmployeeName,
                y: Number(num)
            })
        })

        const updateChart: any = {

            series: [
                {
                    data: ser
                }
            ],
            options: {
                legend: {
                    show: false
                },
                chart: {
                    height: 350,
                    type: 'treemap',
                    animations: {
                        enabled: false
                    }
                },
                colors: colors.colors,
                plotOptions: {
                    treemap: {
                        distributed: true,
                        enableShades: false
                    }
                },
                tooltip: {
                    theme: 'dark',
                },
            },


        };

        setChart(updateChart);
        setShowChart(true);
    }


    useEffect(() => {
        handleData();
    }, [Data])

    const height = 100 + chart?.series[0]?.data?.length * 50


    return (
        <div>
            {showChart && <ReactApexChart options={chart?.options} series={chart?.series} type="treemap" height={height} />}
        </div>
    )
}