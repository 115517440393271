import * as React from "react"
import { Main } from "../components/main/Main";
import "../css/pages/home.css";
import { Dialog } from 'primereact/dialog';
import { useSelector } from "react-redux";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ProSidebar } from "../components/navbars/ProSidebar";

export const Home = () => {
    const err = useSelector((state: any) => state?.error);
    const navigate = useNavigate();
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        setVisible(err?.isError);
    }, [err]);

    const isLogin = useSelector((state: any) => state.login.islogin);

    useEffect(() => {
        if (!isLogin) {
            navigate("/login")
        }
    }, [isLogin, navigate])


    return (
        <div className='home-page'>
            <Dialog header="Error!" visible={visible} style={{ width: '50vw', backgroundColor: 'white' }} onHide={() => setVisible(false)}>
                <p style={{ padding: '1rem 0' }}>
                    {err?.errorMessage}
                </p>
            </Dialog>
            {/* <Sidebar /> */}
            <ProSidebar />
            <Main />
        </div>
    )
}