import { CreateProjectSettingDto } from "../../dtos/projectSetting/CreateProjectSettingDto";
import { get, post } from "../../utills/BaseService";


export async function getAllProjectFromSettings(dispatch: any) {
    return await get(dispatch, '/projectSetting/getAllProjectFromSettings', null)
}

export async function deleteAndCreateProjectSettings(dispatch: any, input: CreateProjectSettingDto[]) {
    return await post(dispatch, '/projectSetting/deleteAndCreateProjectSettings', input)
}