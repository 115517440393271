import { AllProjectInputDto } from "../../dtos/project/AllProjectInputDto";
import { get } from "../../utills/BaseService";


export async function getProjectByUser(dispatch: any) {
    return await get(dispatch, '/project/getProjectByUser', null)
}

export async function getAllSourceTypeForproject(dispatch: any) {
    return await get(dispatch, '/project/getAllSourceType', null)
}

export async function getAllProject(dispatch: any, input: AllProjectInputDto) {
    return await get(dispatch, '/project/getAllProject', input)
}