import axios from "axios";
import { setError } from "../store/slices/errorSlice";
import { AnyAction, Dispatch } from "redux";
import { hideLoader, showLoader } from "../store/slices/loadingSlice";
import { setLogin } from "../store/slices/loginSlice";


let apicallCount = 0;
let apicallCountForPost = 0;

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 300000,
    withCredentials: true,
});
axios.defaults.withCredentials = true;

const header = {
    access_token: "Bearer " + localStorage.getItem("token")
}

function checkExpTime() {
    const TokenExpTime = localStorage.getItem('TokenExpTime');
    let expTics: number = parseInt(TokenExpTime as string);
    const currentTime = new Date();
    //convert time to tics
    const epochOffset = 621355968000000000;
    const ticksPerMillisecond = 10000;

    const currentTicks =
        currentTime.getTime() * ticksPerMillisecond + epochOffset;
    //compare between current and exp time.
    if (currentTicks < expTics) {
        return true
    } else if (currentTicks > expTics || currentTicks === expTics) {
        return false
    }
}


export async function get(dispatch: Dispatch<AnyAction>, url: string, input: any): Promise<any> {
    const isExpire = checkExpTime()
    if (isExpire) {
        apicallCount++;
        dispatch(showLoader())
        return await instance.get(url + '?' + convertObjectToUrl(input), {
            headers: header
        })
            .then(result => {
                if (result?.data?.Success) {
                    apicallCount--;
                    if (apicallCount == 0) {
                        dispatch(hideLoader());
                    }
                    return result?.data?.Result;
                }
            })
            .catch(error => {
                if (error.request.status === 401) {
                    localStorage.clear();
                    dispatch(setLogin({ islogin: false }));
                }
                const err = {
                    isError: true,
                    errorMessage: error?.response?.data?.Error
                }
                dispatch(hideLoader())
            });
    }
    else {
        localStorage.clear();
        dispatch(setLogin({ islogin: false }));
    }
}

export async function post(dispatch: Dispatch<AnyAction>, url: string, input: any) {
    const isExpire = checkExpTime()
    if (isExpire) {
        apicallCountForPost++
        dispatch(showLoader())
        return await instance.post(url, input, {
            headers: header
        }).then(result => {
            if (result?.data?.Success) {
                apicallCountForPost--;
                    if (apicallCountForPost == 0) {
                        dispatch(hideLoader());
                    }
                return result?.data?.Result
            }
        }).catch(error => {
            const err = {
                isError: true,
                errorMessage: error?.response?.data?.Error
            }
            dispatch(hideLoader())
            dispatch(setError(err))
        });
    }
    else {
        localStorage.clear();
        dispatch(setLogin({ islogin: false }));
    }
}

export async function patch(dispatch: Dispatch<AnyAction>, url: string, param: number, input: any) {
    const isExpire = checkExpTime()
    if (isExpire) {

        dispatch(showLoader())
        return await instance.patch(url + '/' + param.toString(), input, {
            headers: header
        }).then(result => {
            if (result?.data?.Success) {
                dispatch(hideLoader())
                return result?.data?.Result
            }
        }).catch(error => {
            const err = {
                isError: true,
                errorMessage: error?.response?.data?.Error
            }
            dispatch(hideLoader())
            dispatch(setError(err))
        });
    }
    else {
        localStorage.clear();
        dispatch(setLogin({ islogin: false }));
    }
}

export async function del(dispatch: Dispatch<AnyAction>, url: string, param: number) {
    const isExpire = checkExpTime()
    if (isExpire) {
        dispatch(showLoader())
        return await instance.delete(url + '/' + param?.toString(), {
            headers: header
        }).then(result => {
            if (result?.data?.Success) {
                dispatch(hideLoader())
                return result?.data?.Result
            }
        }).catch(error => {
            const err = {
                isError: true,
                errorMessage: error?.response?.data?.Error
            }
            dispatch(hideLoader());
            dispatch(setError(err));
        });
    }
    else {
        localStorage.clear();
        dispatch(setLogin({ islogin: false }));
    }
}

export function convertObjectToUrl(input: any): string {
    let params = new URLSearchParams();
    for (let key in input) {
        params.set(key, input[key])
    }
    return params.toString();
}

export default instance;
