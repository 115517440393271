import * as react from 'react';
import ReactApexChart from 'react-apexcharts';
import '../../css/components/dashboard.css';
import { LoggedInPerformanceData } from '../../dtos/performance/LoggedInPerformanceData';

interface props {
  Data: LoggedInPerformanceData[]
}

export const LoggedInEmpPerformanceChart = (props: props) => {

  let ratings: number[] = [];
  let kpis: string[] = [];

  props?.Data.forEach(kpi => {
    ratings.push(kpi.Rating);
    kpis.push(kpi.KpiName)
  })

  const chart = {

    series: [{
      name: 'Ratings',
      data: ratings,
    }],
    options: {
      chart: {
        height: 350,
        type: 'radar',
      },
      xaxis: {
        categories: kpis
      }
    },
  };

  return (
    <div>
      <ReactApexChart options={chart?.options as any} series={chart?.series} type="radar" height={550} />
    </div>
  )
}