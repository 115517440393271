import { TotalTrackAndBillTimeByEmpDto } from "../../dtos/employeeBilling/TotalTrackAndBillTimeByEmpDto";
import { TotalTrackAndBillTimeByProjDto } from "../../dtos/employeeBilling/TotalTrackAndBillTimeByProjDto";
import { post } from "../../utills/BaseService";

export async function getAllReport(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/report/getAllReport', input);
};

export async function getReportExcelData(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/report/getReportExcelData', input);
};
export async function getReportPDFData(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/report/getReportPDFData', input);
};

export async function getTotalTrackAndBillTime(dispatch: any, input: any): Promise<any> {
   return await post(dispatch, '/report/getTotalTrackAndBillTime', input);
};
export async function getTotalTrackAndBillTimeByProj(dispatch: any, input: any): Promise<TotalTrackAndBillTimeByProjDto[]> {
   return await post(dispatch, '/report/getTotalTrackAndBillTimeByProj', input);
};
export async function getTotalTrackAndBillTimeByEmp(dispatch: any, input: any): Promise<TotalTrackAndBillTimeByEmpDto[]> {
   return await post(dispatch, '/report/getTotalTrackAndBillTimeByEmp', input);
};