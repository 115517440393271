import { Card } from "primereact/card";
import "../../css/components/dashboard.css";
import { forwardRef, useEffect, useRef, useState } from "react";
import {
  getRatingsByKpiOfLoggedInUser,
  getTopPerformedEmployee,
} from "../../service/performance/PerformanceService";
import { useDispatch, useSelector } from "react-redux";
import { Toolbar } from "primereact/toolbar";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Calendar } from "primereact/calendar";
import { DashboardInputDto } from "../../dtos/dashboard/DashboardInputDto";
import { ISTToDate } from "../../shared/functions/ISTToDate";
import {
  getTopBilledEmployee,
  getTopTrackingProjects,
  getTopBillingProjects,
  getTotalTrackAndBillTimeByProj,
} from "../../service/employeeBilling/EmployeeBillingService";
import { TopPerformingEmp } from "../../dtos/dashboard/TopPerformingEmp";
import { TopBillingEmpDto } from "../../dtos/dashboard/TopBillingEmpDto";
import { ShowMinToHourAndMin } from "../../shared/functions/ShowMinToHourAndMin";
import { TopBillingProjChart } from "../charts/TopBillingProjChart";
import { LoggedInEmpPerformanceChart } from "../charts/LoggedInEmpPerformanceChart";
import { LoggedInUserData } from "../../shared/functions/LoggedInUserData";
import { TotalRatingsByKpiInputDto } from "../../dtos/performance/TotalRatingsByKpiInputDto";
import { TopTrackingProjChart } from "../charts/TopTrackingProjChart";
import { getSubordinatesNameIDCode } from "../../service/employee/employeeService";
import { isNotUpcomingDate } from "../../shared/functions/IsNotUpcomingDateFunc";
import DatePicker from "react-datepicker";
import { GetPrevFirstAndLastDayOfWeek } from "../../shared/functions/GetPrevFirstAndLastDayOfWeek";
import { GetNextFirstAndLastDayOfWeek } from "../../shared/functions/GetNextFirstAndLastDayOfWeek";
import { LoggedInEmpPerformanceChartForTwo } from "../charts/LoggedInEmpPerformanceChartForTwo";
import { LoggedInPerformanceData } from "../../dtos/performance/LoggedInPerformanceData";
import { TopPerformingProjectDto } from "../../dtos/dashboard/TopPerformingProjectDto";
import { EmployeePerformanceChart } from "../charts/EmployeePerformanceChart";
import { TotalTimeByProjectDto } from "../../dtos/dashboard/TotalTimeByProjectDto";
import { Toast } from "primereact/toast";
import { TopPerformingBillGroupProjDto } from "../../dtos/dashboard/TopPerformingBillGroupProjDto";
import { ShowSecToHourAndMin } from "../../shared/functions/ShowSecToHourAndMin";
import { TopPerformingTrackGroupProjDto } from "../../dtos/dashboard/TopPerformingTrackGroupProjDto";
import instance from "../../utills/BaseService";

export const Dashboard = () => {
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const presentDate = new Date();
  presentDate.setMonth(presentDate.getMonth() - 1);
  const [date, setDate] = useState<any>(presentDate);
  const [dateType, setDateType] = useState<string>("month");
  const [calender, setCalender] = useState(
    <Calendar value={date} onChange={(e) => setDate(e.value)} style={{ backgroundColor: 'red' }} />
  );
  const buttons = [
    { name: "Week", value: "week" },
    { name: "Month", value: "month" },
    { name: "Year", value: "year" },
  ];
  const [topPerformingEmp, setTopPerformingEmp] = useState<TopPerformingEmp[]>(
    []
  );
  const [topBillingEmp, setTopBillingEmp] = useState<TopBillingEmpDto[]>([]);
  const [loggedInEmpPerformanceData, setloggedInEmpPerformanceData] = useState<
    LoggedInPerformanceData[]
  >([]);
  const [topTrackingProjData, setTopTrackingProjData] = useState<TopPerformingTrackGroupProjDto[]>([]);
  const [topBillingProjData, setTopBillingProjData] = useState<TopPerformingBillGroupProjDto[]>([]);
  const [showOnlyUserChart, setShowOnlyUserChart] = useState<boolean>(true);
  const [disableSelectBtn, setDisableSelectBtn] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // for week
  const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() - 6);
  const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() - firstDay.getDay() + 7);

  const [weekDate, setWeekDate] = useState<any>([firstDay, lastDay])
  const [monthDate, setMonthDate] = useState<any>(new Date());
  //for year
  const [yearDate, setYearDate] = useState<any>(new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
  const localization = useSelector((state: any) => state.localization.localization);
  const toast = useRef<any>(null);

  //#region all Functions

  //after clicking the week button dates will set to week

  const handleWeek = () => {
    if (weekDate.length > 0) {
      const dt = [weekDate[0], weekDate[1]];
      setDate(dt);
    }
    else {

      const today = new Date(weekDate);
      const first = new Date(today);
      first.setDate(
        today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
      );
      const last = new Date(today);
      last.setDate(first.getDate() + 6);
      const dateRange = [first, last];
      setDate(dateRange);
    }
  };

  //after clicking the year button dates will set to year
  const handleYear = () => {
    setDate(yearDate);
  };

  //after clicking the month button dates will set to month
  const handleMonth = () => {
    setDate(monthDate);
  };

  //For handling date select component buttons
  const handleDate = (e: any) => {
    let dateTypeForPicker;
    if (e.value === null) {
      dateTypeForPicker = dateType;
      setDateType((prev: any) => {
        return prev;
      });
    } else {
      setDateType(e.value);
      dateTypeForPicker = e.value;
    }

    if (e.value === "week") {
      handleWeek();
    } else if (e.value === "month") {
      handleMonth();
    } else if (e.value === "year") {
      handleYear();
    }
  };

  //For previous date button
  const handlePrevDate = (type: string, date: any) => {
    if (type === "month") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay.getMonth() - 1);
      setDate(firstDay);
      setMonthDate(firstDay)
    } else if (type === "week") {
      const daysOfWk = GetPrevFirstAndLastDayOfWeek(date);
      const dateRange = [];
      dateRange.push(daysOfWk.first, daysOfWk.last);
      setDate(dateRange);
      setWeekDate(dateRange)
    } else if (type === "year") {
      const prevY = new Date(date.setFullYear(date.getFullYear() - 1));
      setDate(prevY);
      setYearDate(prevY)
    }
  };

  //For next date button
  const handleNextDate = (type: string, date: any) => {
    if (type === "month") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay?.getMonth() + 1);

      //if the date is upcoming date then set it current date
      if (!isNotUpcomingDate(firstDay.toString())) {
        setDate((prev: any) => {
          const newDate = prev;
          return newDate
        })
        toast.current.show({ severity: 'warn', summary: 'Sorry!', detail: "You can't go to the current and upcoming months!", life: 3000 });
        return;
      }
      setDate(firstDay);
      setMonthDate(firstDay)
    } else if (type === "week" || type === "dateRange") {
      const daysOfWk = GetNextFirstAndLastDayOfWeek(date);
      const dateRange = [];
      dateRange.push(daysOfWk.first, daysOfWk.last);
      setDate(dateRange);
      setWeekDate(dateRange)
    } else if (type === "year") {
      const nextY = new Date(date.setFullYear(date.getFullYear() + 1));
      setDate(nextY);
      setYearDate(nextY)
    }
  };

  //This function will give first and last day of month from a selected single date
  function getFirstAndLastDatesOfMonth(inputDate: Date) {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    return {
      firstDay,
      lastDay,
    };
  }
  const getFirstAndLastDayOfWeek = (date: any) => {
    const today = new Date(date[0]); // Create a clone of the input date

    // Set the first day of the week (Monday)
    const firstDay = new Date(today);
    firstDay.setDate(
      today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
    );

    // Set the last day of the week (Sunday)
    const lastDay = new Date(firstDay);
    lastDay.setDate(firstDay.getDate() + 6);

    const weekDates = [firstDay, lastDay];
    setDate(weekDates); // Assuming setDate is a function you want to use to set the week dates
    setWeekDate(weekDates)
  };


  //This function will decide which calender will show according to the dateType
  const handleCalender = () => {
    if (dateType === "month") {
      setCalender(
        <DatePicker
          selected={date}
          customInput={<CustomInput />}
          value={date}
          onChange={(e: any) => setDate(e)}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
        />
      );
      setMonthDate(date)
    } else if (dateType === "week") {
      setCalender(
        <DatePicker
          selectsRange={date}
          customInput={<CustomInput />}
          startDate={date[0]}
          endDate={date[1]}
          onChange={(e: any) => getFirstAndLastDayOfWeek(e)}
          dateFormatCalendar="MMMM yyyy"
        />
      );
    } else if (dateType === "year") {
      setCalender(
        <DatePicker
          selected={date}
          value={date}
          customInput={<CustomInput />}
          onChange={(e) => setDate(e)}
          dateFormat="yyyy"
          showYearPicker
        />
      );
      setYearDate(date)
    }
  };

  const createStartAndEndDate = (input: Date[]) => {
    let dates: DashboardInputDto = {
      startDate: "",
      endDate: "",
    };
    if (date.length > 0) {
      const allStrDates = ISTToDate(input);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    }
    //If there is only a single date and Month button is selected
    else if (date.length === undefined && dateType === "month") {
      const firstAndLastDate = getFirstAndLastDatesOfMonth(date);
      const dt = [firstAndLastDate.firstDay, firstAndLastDate.lastDay];
      const allStrDates = ISTToDate(dt);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    } else if (dateType === "year") {
      const currentYear = date.getFullYear();
      const allStrDates = ISTToDate([
        new Date(currentYear, 0, 1),
        new Date(currentYear, 11, 31),
      ]);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    }
    return dates;
  };

  const handleTopPerformingEmp = async () => {
    const inputForPresentDate = dateType === "week" ? date[0] : date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (isPresentDate && dateType !== 'week') {
      let input;
      input = createStartAndEndDate(date);
      const emp = await getTopPerformedEmployee(dispatch, input);
      if (emp) {
        setTopPerformingEmp(emp);
      }
    } else {
      setTopPerformingEmp([]);
    }
  };

  const handleTopBillingEmp = async () => {
    const inputForPresentDate = dateType === "week" ? date[0] : date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (isPresentDate && dateType !== 'week') {
      const input = createStartAndEndDate(date);
      const emp = await getTopBilledEmployee(dispatch, input);
      if (emp) {
        setTopBillingEmp(emp)
        const promises = emp.map(async (e: any) => {
          const image = await getProfilePicFromTracker(e.EmployeeID);
          e.ProfilePicture = image;
          return e;
        });

        // Wait for all the Promises to resolve
        await Promise.all(promises);
        setTopBillingEmp(emp);

      }
    } else {
      setTopBillingEmp([]);
    }
  };

  const handleTopPerformingProj = async () => {
    let dateTypeForTracker = 0;
    switch (dateType) {
      case "week":
        dateTypeForTracker = 1
        break;
      case "month":
        dateTypeForTracker = 2
        break;
      case "year":
        dateTypeForTracker = 3
        break;
    }
    const inputForPresentDate = dateType === "week" ? date[1] : date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (isPresentDate) {
      const dates = createStartAndEndDate(date);
      const input = {
        dateType: dateTypeForTracker,
        startDate: dates.startDate,
        endDate: dates.endDate,
      };
      const inputForTimes = {
        startDate: dates.startDate,
        endDate: dates.endDate,
      };
      const topBillingProj: TopPerformingBillGroupProjDto[] = await getTopBillingProjects(dispatch, input);
      const topTrackingProj: TopPerformingTrackGroupProjDto[] = await getTopTrackingProjects(dispatch, input);

      if (topTrackingProj.length > 0) {
        setTopTrackingProjData(topTrackingProj)
      }
      else {
        setTopTrackingProjData([])
      }

      if (topBillingProj.length > 0) {
        setTopBillingProjData(topBillingProj)
      }
      else {
        setTopBillingProjData([])
      }
    }
    else {
      setTopBillingProjData([]);
      setTopTrackingProjData([])
    }
  };

  const handleloggedInEmpPerformanceChart = async () => {
    const inputForPresentDate = dateType === "week" ? date[0] : date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);

    if (isPresentDate && dateType !== 'week') {
      const loggedInUser = await LoggedInUserData(dispatch);
      let dates;
      if (dateType === "week") {
        const monthDates = getFirstAndLastDatesOfMonth(date[0]);
        const dt = [monthDates.firstDay, monthDates.lastDay];
        dates = createStartAndEndDate(dt);
      } else {
        dates = createStartAndEndDate(date);
      }
      const input: TotalRatingsByKpiInputDto = {
        empCode: loggedInUser?.EmployeeCode,
        startDate: dates?.startDate,
        endDate: dates?.endDate,
      };

      if (date) {
        const res: LoggedInPerformanceData[] =
          await getRatingsByKpiOfLoggedInUser(dispatch, input);
        if (res) {
          if (Object?.keys(res)?.length > 0) {
            setloggedInEmpPerformanceData(res);
          }
          else {
            setloggedInEmpPerformanceData([]);
          }
        }
      }
    }
  };

  const getAllSubordinates = async () => {
    const res = await getSubordinatesNameIDCode(dispatch);
    if (res?.length <= 1) {
      setShowOnlyUserChart(false);
    }
  };

  const handleAdminChart = () => {
    const role = localStorage.getItem("role");
    if (role === "admin") {
      setIsAdmin(true);
    }
  };

  const getProfilePicFromTracker = async (userId: number) => {
    let image = ''
    const headers = {
      access_token: "Bearer " + localStorage.getItem("token"),
    };

    const response = await instance.get(`/profilePicture/getUserProfilePicture?userID=${userId}`, {
      headers,
      responseType: 'blob'
    });

    image = URL.createObjectURL(response.data);
    return image;
  }

  //#endregion

  //#region all Templates
  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));


  const endToolbar = () => {
    return (
      <div className="report-start-tool">
        <SelectButton
          value={dateType}
          onChange={(e) => handleDate(e)}
          optionLabel="name"
          options={buttons}
          disabled={disableSelectBtn}
        />

        {/* visible input calender */}

        <div className="date-input">
          <Button
            icon={<AiOutlineLeft />}
            onClick={() => handlePrevDate(dateType, date)}
            className="date-comp-prev-btn"
            disabled={disableSelectBtn}
          />
          {calender}

          <Button
            icon={<AiOutlineRight />}
            onClick={() => handleNextDate(dateType, date)}
            className="date-comp-next-btn"
            disabled={disableSelectBtn}
          />
        </div>
      </div>
    );
  };
  //#endregion

  //#region all UseEffects

  const handleAllFunc = async () => {
    setDisableSelectBtn(true);
    await Promise.all([handleloggedInEmpPerformanceChart(), handleTopBillingEmp(), handleTopPerformingEmp(), handleTopPerformingProj()])
    setDisableSelectBtn(false);
  };


  useEffect(() => {
    handleCalender();
    handleAllFunc();
    handleAdminChart();
  }, [dateType, date, mode?.color, showOnlyUserChart, window.location.pathname]);

  useEffect(() => {
    getAllSubordinates();
  }, [])

  //#endregion
  return (
    <div>
      <Toast ref={toast} />
      <Toolbar
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="dashboard_sec mb-3"
      />
      <div className="main-dashboard row">
        {/* TOP BILLING EMPLOYEE ******************/}
        {dateType !== 'week' &&
          <div className="col-lg-4 mb-3 pr-1">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-billing-emp"
            >
              <h3 className="dashboard-card-head">{localization?.TopBillingEmployee || "Top Billing Employee"}</h3>
              {topBillingEmp.length > 0 ? (
                <div>
                  {topBillingEmp?.map((emp) => (
                    <div
                      key={emp?.EmployeeID}
                      className="top-performing-emp-box"
                    >
                      <div className="employees-picture">
                        <img
                          src={emp?.ProfilePicture || 'https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg'}
                          alt="https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg" onError={(event: any) => event.target.src = 'https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg'}
                        />
                      </div>
                      <div className="dashboard-emp">
                        <p>
                          <span className="head-text">Name:</span>{" "}
                          {emp?.EmployeeName}
                        </p>
                        <p>
                          <span className="head-text">Code: </span>{" "}
                          {emp?.EmployeeCode}
                        </p>
                        <p>
                          <span className="head-text">Billing Time: </span>{" "}
                          {ShowMinToHourAndMin("", emp?.BillingTimeInMinute)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div style={{ color: mode?.color }} className="no-data">
                  No data to show
                </div>
              )}
            </Card>
          </div>
        }
        {
          (!isAdmin && dateType !== 'week') &&
          <div className={isAdmin ? "col-md-12 mb-3" : "col-md-8 mb-3"}>
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="user-performance-chart"
            >
              <h3 className="dashboard-card-head">{localization?.YourPerformanceChart || "Your Performance Chart"}</h3>
              {loggedInEmpPerformanceData?.length <= 2 &&
                loggedInEmpPerformanceData?.length > 0 ? (
                <LoggedInEmpPerformanceChartForTwo
                  Data={loggedInEmpPerformanceData}
                />
              ) : loggedInEmpPerformanceData?.length > 2 ? (
                <LoggedInEmpPerformanceChart Data={loggedInEmpPerformanceData} />
              ) : (
                <div style={{ color: mode?.color }} className="no-data">
                  No data to show
                </div>
              )}
            </Card>
          </div>
        }

        {/* Employee performance chart ****************/}
        {dateType !== 'week' &&
          <div className={isAdmin ? "col-lg-8 mb-3" : "col-md-12 mb-3"}>
            <Card style={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
              className="top-billing-emp"
            >
              <h3 className="dashboard-card-head">{localization?.EmployeePerformance || "Employee Performance"}</h3>
              {
                topPerformingEmp.length > 0 ?
                  (<EmployeePerformanceChart Data={topPerformingEmp} />) :
                  (<div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>)
                // <EmployeePerformanceChart Data={topPerformingEmp} />
              }
            </Card>
          </div>
        }

        {/* TOP Billing PROJECTS ******************/}
        {
          <div className="col-md-6 mb-3 pr-1">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-billing-proj"
            >
              <h3 className="dashboard-card-head">{localization?.TopBillingProjects || "Top Billing Projects"}</h3>
              {topBillingProjData?.length > 0 ? (
                <TopBillingProjChart
                  data={topBillingProjData}
                  dateType={dateType}
                  date={date}
                />
              ) : (
                <div style={{ color: mode?.color }} className="no-data">
                  No data to show
                </div>
              )}
              {topBillingProjData?.length > 0 && (
                <div className="total-times">
                  {topBillingProjData?.map(val => (
                    <ul key={val.projectId}>
                      <li>
                        {val.projectName} :{" "}
                        {ShowSecToHourAndMin(val.totalBill as number)}
                      </li>
                    </ul>
                  ))}
                </div>
              )}
            </Card>
          </div>
        }

        {/* TOP Tracking PROJECTS ******************/}
        {
          <div className="col-md-6 mb-3">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-tracking-proj"
            >
              <h3 className="dashboard-card-head">{localization?.TopTrackingProjects || "Top Tracking Projects"}</h3>
              {topTrackingProjData?.length > 0 ? (
                <TopTrackingProjChart
                  data={topTrackingProjData}
                  dateType={dateType}
                  date={date}
                />
              ) : (
                <div style={{ color: mode?.color }} className="no-data">
                  No data to show
                </div>
              )}
              {topTrackingProjData?.length > 0 && (
                <div className="total-times">
                  {topTrackingProjData?.map(val => (
                    <ul key={val.projectId}>
                      <li>
                        {val.projectName} :{" "}
                        {ShowSecToHourAndMin(val?.totalTrack as number)}
                      </li>
                    </ul>
                  ))}
                </div>
              )}
            </Card>
          </div>
        }
      </div>
    </div>
  );
};
