// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.proj-settings-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    gap: 0.9rem;
}

.project-container {
    border: 1px solid hwb(0deg 83.15% 16.85%);
    display: flex;
    flex-wrap: wrap;
    gap: 8.2px;
    margin: 12.4px 2px;
    padding: 18px;
    max-height: 16rem;
    border-radius: 7.2px;
    overflow-y: scroll;
}

.project_single{
    height: calc(100vh - 250px);
    padding: 0 0.8rem 0 0;
    overflow-y: auto;
}
.project_single_box{
    height: -moz-fit-content;
    height: fit-content;
}
@media screen and (max-width:500px) {
    .proj-settings-head{
        flex-direction: column;
        align-items: start;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/components/projectSettings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,yCAAyC;IACzC,aAAa;IACb,eAAe;IACf,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,qBAAqB;IACrB,gBAAgB;AACpB;AACA;IACI,wBAAmB;IAAnB,mBAAmB;AACvB;AACA;IACI;QACI,sBAAsB;QACtB,kBAAkB;IACtB;AACJ","sourcesContent":[".proj-settings-head {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 16px;\n    gap: 0.9rem;\n}\n\n.project-container {\n    border: 1px solid hwb(0deg 83.15% 16.85%);\n    display: flex;\n    flex-wrap: wrap;\n    gap: 8.2px;\n    margin: 12.4px 2px;\n    padding: 18px;\n    max-height: 16rem;\n    border-radius: 7.2px;\n    overflow-y: scroll;\n}\n\n.project_single{\n    height: calc(100vh - 250px);\n    padding: 0 0.8rem 0 0;\n    overflow-y: auto;\n}\n.project_single_box{\n    height: fit-content;\n}\n@media screen and (max-width:500px) {\n    .proj-settings-head{\n        flex-direction: column;\n        align-items: start;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
